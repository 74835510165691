import {Amount} from "@/model/amount";
import {WarriorClass} from "@/store/level/types";

export class ArtifactInfo {
    artifactId!: string;
    atlasKey!: string;
    imageKey!: string;
    name!: string;
    effectType!: EffectType;
    effectValue!: number;
    possibleCreatureIds!: number[];

    levelsCount!: number;
    maxEffectValue!: number;
}

export class RuneInfo {
    runeId!: string;
    atlasKey!: string;
    imageKey!: string;
    name!: string;
    multiUse!: boolean;
    level!: number;
    artNames!: string[];
}

export enum EffectType {
    ATTACK = 'ATTACK',
    DEFENCE = 'DEFENCE',
    ATTACK_DEFENCE = 'ATTACK_DEFENCE',
    HEALTH = 'HEALTH',
    KOSCHEY_ATTACK = 'KOSCHEY_ATTACK',
    KOSCHEY_DEFENCE = 'KOSCHEY_DEFENCE',
    POISON = 'POISON',
    RESURRECTION = 'RESURRECTION',
    AMULET_INKUB = 'AMULET_INKUB',
    AMULET_SUKKUB = 'AMULET_SUKKUB',
    AMULET_WITCH = 'AMULET_WITCH',
    AMULET_HAMMER = 'AMULET_HAMMER',
    SHADOW_ENFORCE = 'SHADOW_ENFORCE',
    AMULET_GORGON = 'AMULET_GORGON'
}

export class Artifact {
    id!: number;
    effectType!: EffectType;
    effectValue!: number;
    groupId!: number;
    artifactId!: string;
    atlasKey!: string;
    imageKey!: string;
    name!: string;
    level!: number;
    updatingTill!: number;
}

export class UpdateInfo {
    artifactId!: string;
    currentLevel!: number;
    effectType!: EffectType;
    currLevelValue!: number;
    nextLevelValue!: number;
    maxLevelValue!: number;
    atlasKey!: string;
    currImageKey!: string;
    nextImageKey!: string;
    currName!: string;
    nextName!: string;
    updateTimeSec!: number;
    amount!: Amount;
    requiredRunes!: RequiredRune[];
    updateOverCharge!: number;
    minLevel!: number;

    id!: number;
    updatingTill!: number;
    speedupPrice!: number;
    canUpgrade!: boolean;
}

export class RequiredRune {
    atlasKey!: string;
    imageKey!: string;
    name!: string;
    present!: boolean;
}

export class SpeedupInfo {
    currLevel!: number;
    currName!: string;
    nextName!: string;
    atlasKey!: string;
    currImageKey!: string;
    nextImageKey!: string;
    effectType!: EffectType;
    currLevelValue!: number;
    nextLevelValue!: number;
    updatingTill!: number;
    speedupPrice!: number;
}

export class UnlockInfo {
    price!: number;
}

export class ArtifactNotification {
    id!: number;
    playerId!: number;
    prevItemName!: string;
    nextItemName!: string;
    atlasKey!: string;
    prevImageKey!: string;
    nextImageKey!: string;
    prevLevelValue!: number;
    nextLevelValue!: number;
    effectType!: EffectType;
    itemLevel!: number;
}

export class ForceNotification {
    id!: number;
    playerId!: number;
    creatureId!: number;
    warriorClass!: WarriorClass;
    prevLevel!: number;
    nextLevel!: number;
}


export class GroupInfo {
    name!: string;
    atlasKey!: string;
    imageKey!: string;
    levels!: LevelInfo[];
    intro!: string;
    conclusion!: string;
}

export class LevelInfo {
    level!: number;
    current!: boolean;
    effectType!: EffectType;
    effectValue!: number;
    updateSec!: number;
    speedupGems!: number;
    updatePrice!: Amount;
}
