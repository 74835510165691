import {Creature, Force, Skill, WarriorClass} from "@/store/level/types";
import {Consumable} from "@/store/consumable/types";
import {SkillType} from "@/store/place/types";

export interface BattleState {
    battleStatus: BattleStatus | undefined;
}

export class BattleStatus {
    battle!: Battle;
    moves!: BattleMove[];
    battleState!: string;
    winnerReward!: RewardDto;
    looserPenalty!: PenaltyDto;
    healthPenalty!: number;
    consumables!: Consumable[];
    scenarioMessage: string | undefined;
    battleType: BattleType | undefined;
    battleName: string | undefined;
    enemyClanName: string | undefined;
    turningTeam!: TeamTag | undefined;
    ask!: boolean;
}

export enum TeamTag {
    TM1 = "TM1",
    TM2 = "TM2",
    TM3 = "TM3",
    TM4 = "TM4",
    TM5 = "TM5",
    PLAYER = "PLAYER",
}

export class Battle {
    id!: number;
    placeId!: number;
    specialMusicKey: string | undefined;
    creatures!: Creature[];
    backgroundKey!: string;
}

export enum BattleType {
    MOB = 'MOB',
    ARENA = 'ARENA',
    MULTI = 'MULTI'
}

export class MovePrediction {
    attackerId!: number;
    defenderId!: number;

    attackerSkillDeltas!: SkillDelta[];
    defenderSkillDeltas!: SkillDelta[];

    blockChancePercent!: number;
}

export enum NextMove {
    FAST = "FAST",
    HALF_FAST = "HALF_FAST",
    ULTRA_FAST = "ULTRA_FAST",
    DEFAULT = "DEFAULT",
    NOT_LUNCH = "NOT_LUNCH",
}

export class BattleMove {
    attackerId!: number;
    defenderId!: number;

    attackerSkillDeltas!: SkillDelta[];
    defenderSkillDeltas!: SkillDelta[];

    attackerChangedSkills!: Skill[];
    defenderChangedSkills!: Skill[];

    nextMove!: NextMove;
    cmnt!: string;
    delay!: number;
    phrases!: Phrase[];

    blesses!: Bless[];
    effects!: Effect[];

    lostDefenderForces!: Force[];

    restoredAttackerForces!: Force[];
    restoredDefenderForces!: Force[];
}

export class Bless {
    blesserId!: number;
    blessType!: BlessType;
    blesserClass!: BlesserClass;
    queueNumber!: number;
    delta!: number;
    warriorClass!: WarriorClass;
}

export enum BlessType {
    PARAMS = 'PARAMS',
    RESURRECT = 'RESURRECT',
    ADD_RESURRECTION = 'ADD_RESURRECTION',
    ADD_POISON = 'ADD_POISON',
    UP_FORCE = 'UP_FORCE',
    DOWN_FORCE = 'DOWN_FORCE',
    LUCK = 'LUCK',
    URGENT_MOVE = 'URGENT_MOVE'
}

export enum BlesserClass {
    NUN = 'NUN',
    MARTYR = 'MARTYR',
    BERSERK = 'BERSERK',
    HUMAN = 'HUMAN',
    VAMPIRE_ENEMY = 'VAMPIRE_ENEMY',
    VAMPIRE_OWN = 'VAMPIRE_OWN',
    PHOENIX_OWN = 'PHOENIX_OWN',
    PHOENIX_ENEMY = 'PHOENIX_ENEMY'
}

export class Phrase {
    creatureId!: number;
    delayMs!: number;
    text!: string;
}

export class SkillDelta {
    skillType!: SkillType;
    skillDelta!: number;
}

export class RewardDto {
    gold!: number;
    elixir!: number;
    crystal!: number;
    mithril!: number;
    sulfur!: number;
    experience!: number;
    expAddition!: number;
    cups!: number;
    tickets!: number;
    attack!: number;
    defence!: number;
}

export class PenaltyDto {
    cups!: number;
    health!: number;
    recoverSec!: number;
}

export enum BattleStateType {
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED"
}

export enum AttackerEffect {
    NONE = "NONE",
    LUCK = "LUCK"
}

export enum BattleEffect {
    NO_EFFECT = "NO_EFFECT",
    RESURRECTION = "RESURRECTION",
    GOLEM_BLOCK = "GOLEM_BLOCK",
    CLAN_BLOCK = "CLAN_BLOCK"
}

export enum HitMode {
    DEFAULT = "DEFAULT",
    FIREBALL = "FIREBALL",
}

export enum Effect {
    RESURRECTION = "RESURRECTION",
    BLOCK_BLUE = "BLOCK_BLUE",
    BLOCK_YELLOW = "BLOCK_YELLOW",
    BLOCK_RED = "BLOCK_RED",
    BLOCK_VIOLET = "BLOCK_VIOLET",
    HAMMER_RED = "HAMMER_RED",
    HAMMER_VIOLET = "HAMMER_VIOLET",
    BLOCK_CLAN = "BLOCK_CLAN",
    LUCK = "LUCK",
    SHADOW = "SHADOW",
    QUADRATIC = "QUADRATIC",
    SHAPESHIFT = "SHAPESHIFT",
    SPEED_X2 = "SPEED_X2",
    NO_CONTACT = "NO_CONTACT",
    ALLY_RESURRECT = "ALLY_RESURRECT",
    ALLY_HEAL = "ALLY_HEAL",
    ALLY_KOSCHEY = "ALLY_KOSCHEY",
    ALLY_POISON = "ALLY_POISON",
    ALLY_FORCE = "ALLY_FORCE",
    ALLY_ATTACK = "ALLY_ATTACK",
    FREEZE = "FREEZE",
    FIRE = "FIRE",
    UNFREEZE = "UNFREEZE",
    UNFIRE = "UNFIRE",
    HIT_BY_FIRE = "HIT_BY_FIRE",
    RIDER = "RIDER",
    DEFENDER_INKUB = "DEFENDER_INKUB",
    DEFENDER_SUKKUB = "DEFENDER_SUKKUB",
    ATTACKER_INKUB = "ATTACKER_INKUB",
    ATTACKER_SUKKUB = "ATTACKER_SUKKUB"
}
