import {SkillType} from "@/store/place/types";
import {Artifact} from "@/store/artifact/types";
import {ForceUpgrade} from "@/store/force/types";

export interface LevelState {
    creatures: Creature[];
}

export class Creature {
    id!: number;
    name!: string;
    experience!: number;
    skills!: Skill[];
    imageId!: string;
    identifier!: string;
    battleId!: number;
    playerId!: number;
    artifacts!: Artifact[];
    forceUpgrades!: ForceUpgrade[];
    slotsCount!: number;
    temporal!: boolean;
    warriorClass!: WarriorClass;
    combative!: boolean;
    protoStair!: number;
    actualStair!: number;
    forcesV2!: Force[];
    level!: number;
    isGlow: boolean = false;
    rankDescription!: string;
    effects!: CreatureEffect[];
}

export class CreatureEffect {
    type!: CreatureEffectType;
    attackerId!: number;
    movesRemain!: number;
}

export enum CreatureEffectType {
    FROZEN = 'FROZEN',
    FIRED = 'FIRED',
    NUN_LUCK = 'NUN_LUCK',
    NUN_URGENT_MOVE = 'NUN_URGENT_MOVE',
    RIDER_ATTACK = 'RIDER_ATTACK'
}

export class Force {
    warriorClass!: WarriorClass;
    level!: number;
}

export class Skill {
    id!: number;
    skillType!: SkillType;
    valueCurrent!: number;
    valueTotal!: number;
}

export class LevelUpDto{
    upgradeCreaturesIdList!: number[];
    skillType!: SkillType;
    skillDelta!: number;
    skillAddition!: number;
}

export enum WarriorClass {
    BEAST = "BEAST",
    NECRO = "NECRO",
    GIN = "GIN",
    DRAGON = "DRAGON",
    GOLEM = "GOLEM",
    MAG = "MAG",
    HUMAN = "HUMAN",
    PHOENIX = "PHOENIX",
    ASG_PHOENIX = "ASG_PHOENIX",


    DEMON = "DEMON",
    WITCH = "WITCH",
    DRAGONSLAYER = "DRAGONSLAYER",
    DRUID = "DRUID",
    SHAMAN = "SHAMAN",
    SERPENT = "SERPENT",
    TROLL = "TROLL",
    SKULD = "SKULD",
    LIZARD = "LIZARD",



IFRIT = "IFRIT",
    LIFE = "LIFE",
    DEATH = "DEATH",
    WIND = "WIND",
    CHAOS = "CHAOS",
    WALL = "WALL",
    ELF = "ELF",
    VAMPIRE = "VAMPIRE",
    LORD = "LORD",
    LUCK = "LUCK",
    BERSERK = "BERSERK",
    SORCERER = "SORCERER",

    WARLOCK = "WARLOCK",

    TITAN = "TITAN",
    ARCHITITAN = "ARCHITITAN",
    ANGEL = "ANGEL",
    ARCHANGEL = "ARCHANGEL",
    YOTUN = "YOTUN",

    KOSCHEY = "KOSCHEY",
    CYCLOP = "CYCLOP",
    POISON = "POISON",
    KEEPER = "KEEPER",
    SPHINX = "SPHINX",
    NORN = "NORN",
    PROVOKATOR = "PROVOKATOR",
    SHADOW = "SHADOW",
    PRIESTESS = "PRIESTESS",
    AS = "AS",
    EMPTY = "EMPTY",
    DRYAD = "DRYAD",
    FACELESS = "FACELESS",
    GORGON = "GORGON",
    LIGHTSHADOW = "LIGHTSHADOW",
    INKUB = "INKUB",
    SUKKUB = "SUKKUB",
    MANTICORE = "MANTICORE",
    RIDER = "RIDER",
    NUN = "NUN",
    FIRE = "FIRE",
    MARTYR = "MARTYR",
    ABYSSSOUL = "ABYSSSOUL",
    BABAYAGA = "BABAYAGA",
    BALTAZAR = "BALTAZAR",
    SIKJI = "SIKJI",
}
