import Scene = Phaser.Scene;
import Text = Phaser.GameObjects.Text;
import BitmapText = Phaser.GameObjects.BitmapText;
import {Fonts} from "@/constants/font";
import Sprite = Phaser.GameObjects.Sprite;
import {Keys} from "@/constants/key";
import {Player} from "@/store/player/types";
import SpriteUtils from "@/utils/sprite-utils";
import CU from "@/utils/c-u";
import { Gender } from '@/model/gender';
import { Tr } from '@/i18n/i18n';

const PLAYER_NAME_PLACEHOLDER = '${playerName}';
const TO_PLAYER_PLACEHOLDER = '${toPlayer}';
const FOR_PLAYER_PLACEHOLDER = '${forPlayer}';

export default class TextUtils {

    public static addText(scene: Scene, content: string, x: number, y: number, color: string, size: number, fontStyle: string, width: number): Text {
        return TextUtils.addTextAdvanced(scene, content, x, y, color, size, fontStyle, width, 'center', 2, 'Arial');
    }

    public static addTextByFamily(scene: Scene, content: string, x: number, y: number, color: string, size: number,
                                  fontStyle: string, width: number, fontFamily: string): Text {
        return TextUtils.addTextAdvanced(scene, content, x, y, color, size, fontStyle, width, 'center', 2, fontFamily);
    }

    public static addTextAdvanced(scene: Scene, content: string, x: number, y: number, color: string, size: number,
                                  fontStyle: string, width: number, align: string, lineSpacing: number, fontFamily: string): Text {
        const text: Text = scene.add.text(CU.shiftLeft(x), CU.shiftTop(y), content,{
            fontSize: String(Math.ceil(size * CU.S)) + 'pt',
            color: color,
            fontFamily: fontFamily,
            align: align,
            fontStyle: fontStyle,
            wordWrap: { width: width * CU.S, useAdvancedWrap: true }
        }).setOrigin('left' === align ? 0 : 0.5, 0.5);
        text.setLineSpacing(lineSpacing);
        text.setShadow(-1, 2, '#000000');
        return text;
    }

    public static addBMText(scene: Scene, content: string, x: number, y: number, size: number,
        width: number, align: TextAlign, lineSpacing: number, fontFamily: FontsKeys): BitmapText {
            const bmText: BitmapText = scene.add.bitmapText(CU.shiftLeft(x), CU.shiftTop(y), fontFamily, content)
                                    .setMaxWidth(width)
                                    .setFontSize(Math.ceil(size * CU.S) + 1)
                                    .setOrigin(TextAlign.LEFT === align ? 0 : 0.5, 0.5);
                                        
                switch (align) {
                    case TextAlign.LEFT:
                        bmText.setLeftAlign();
                        break;
                    case TextAlign.CENTER:
                        bmText.setCenterAlign();
                        break;
                    case TextAlign.RIGHT:
                        bmText.setRightAlign();
                        break;
                }
                
                bmText.lineSpacing = lineSpacing;
        
                return bmText;
    }

    public static addTextAdvancedNoWidth(scene: Scene, content: string, x: number, y: number, color: string, size: number,
                                         fontStyle: string, width: number, align: string, lineSpacing: number, fontFamily: string): Text {
        const text: Text = scene.add.text(x, y, content,{
            fontSize: String(Math.ceil(size * CU.S)) + 'pt',
            color: color,
            fontFamily: fontFamily,
            align: align,
            fontStyle: fontStyle
        }).setOrigin('left' === align ? 0 : 0.5, 0.5);
        text.setLineSpacing(lineSpacing);
        text.setShadow(-1, 2, '#000000');
        return text;
    }

    public static setupCustomFonts() {
        /** Сюда добавлять название каждого нового кастомного шрифта из main9.css
         *
         * Из-за того, что новый шрифт подтягивается только после повторного вызова переменной
         * к которой этот шрифт будет применен, появляется следующая особенность -
         * первый вызов переменной(текст) идет с дефолтным шрифтом.
         * По-этому в onCreate прогоняется пустая строка с новым шрифтом,
         * что по сути "подключает" его к сцене.
         *
         * https://hacks.mozilla.org/2016/06/webfont-preloading-for-html5-games/
         **/

        let webFont = require('webfontloader');

        webFont.load({
            custom: {
                families: [Fonts.GOTHIC_DEUTSCH, Fonts.MYRIADPRO_BOLD, Fonts.MYRIADPRO_REGULAR, Fonts.ARIAL_MT, Fonts.BENGALY_NORMAL,
                           Fonts.ARIAL_MT]
            }
        })
    }


    public static chooseWord(count: number, forOne: string, forTwo: string, forFive: string) {

        if ((count % 100) >= 11 && (count % 100) <= 14) {
            return forFive;
        }

        let countAsString: string = count.toString();

        let lastDigit: string = countAsString.charAt(countAsString.length - 1);

        if (lastDigit == '1') {
            return forOne;
        }

        if (lastDigit >= '2' && lastDigit <= '4') {
            return forTwo;
        }

        return forFive;

    }

    public static formatGems(gems: number): string {
        if (gems > 1000000 && gems % 100000 === 0) {
            if (gems > 1000000000 && gems % 100000000 === 0) {
                return String(gems / 1000000000) + 'G';
            }
            return String(gems / 1000000) + 'M';
        } else if (gems > 10000 && gems % 1000 === 0) {
            return String(gems / 1000) + 'K';
        }
        return String(gems);
    }

    public static formatMoney(money: number): string {
        if (money < 0) {
            return "-" + TextUtils.formatMoney(-money);
        }
        money = Math.round(money);

        if (money < 1000) {
            return String(money);
        }

        if (money < 1000000) {
            return TextUtils.scaledFormat(money, 1000) + 'K';
        }

        if (money < 1000000000) {
            return TextUtils.scaledFormat(money, 1000000) + 'M';
        }

        if (money < 1000000000000) {
            return TextUtils.scaledFormat(money, 1000000000) + 'G';
        }

        if (money < 1000000000000000) {
            return TextUtils.scaledFormat(money, 1000000000000) + 'T';
        }

        if (money < 1000000000000000000) {
            return TextUtils.scaledFormat(money, 1000000000000000) + 'P';
        }

        if (money < 1000000000000000000000) {
            return TextUtils.scaledFormat(money, 1000000000000000000) + 'E';
        }

        if (money < 1000000000000000000000000) {
            return TextUtils.scaledFormat(money, 1000000000000000000000) + 'S';
        }

        if (money < 1000000000000000000000000000) {
            return TextUtils.scaledFormat(money, 1000000000000000000000000) + 'Y';
        }

        if (money < 1000000000000000000000000000000) {
            return TextUtils.scaledFormat(money, 1000000000000000000000000000) + 'R';
        }

        if (money < 1000000000000000000000000000000000) {
            return TextUtils.scaledFormat(money, 1000000000000000000000000000000) + 'Q';
        }

        if (money < 1000000000000000000000000000000000000) {
            return TextUtils.scaledFormat(money, 1000000000000000000000000000000000) + 'F';
        }

        if (money < 1000000000000000000000000000000000000000) {
            return TextUtils.scaledFormat(money, 1000000000000000000000000000000000000) + 'D';
        }

        return TextUtils.scaledFormat(money, 1000000000000000000000000000000000000) + 'A';
    }

    public static trimDigitsAfter(value:number, digitsToLeft:number):number{
        let digitsCount = Math.floor(Math.log10(value)) + 1;
        if (digitsCount <= digitsToLeft || value < 10000) {
            return value;
        }
        let count = Math.pow(10, digitsCount - digitsToLeft);
        return Math.floor(value / count) * count;
    }

    protected static scaledFormat(money: number, scale: number) {

        let m = money / (scale / 10);

        let ret = Math.round(m);

        let s = String(ret);

        if (s.length < 2) {
            return "0." + s;
        } else {
            let res = s.substring(0, s.length - 1) + "." + s.substring(s.length - 1);

            let undesiredEnding = ".0";
            if (res.length > undesiredEnding.length && res.endsWith(undesiredEnding)) {
                res = res.substring(0, res.length - undesiredEnding.length);
            }
            return res;
        }
    }

    public static addStairText(scene: Scene, stair: number, player: Player, x: number, y: number): Sprite {
        let sn = Math.floor((stair - 1) / 100);
        return SpriteUtils.addSpriteN(scene, x, y, TextUtils.getStairKey(stair, player)[sn], stair - 1 - sn * 100);
    }

    private static getStairKey(creatureStair: number, player: Player): string[] {
        if (!creatureStair) {
            return Keys.STAIRS_GREEN;
        }

        let red = TextUtils.getRedStairByPlayerStair(player);
        let yellow = TextUtils.getYellowStairByPlayerStair(player);
        if (creatureStair >= red) {
            return Keys.STAIRS_RED;
        } else if (creatureStair >= yellow) {
            return Keys.STAIRS_YELLOW;
        } else {
            return Keys.STAIRS_GREEN;
        }
    }

    private static getYellowStairByPlayerStair(player: Player) {
        if (player.stair <= 25) {
            return player.battleStair + 1;
        } else if (player.stair < 60) {
            return player.battleStair;
        } else if (player.stair < 100) {
            return player.battleStair - 1;
        } else if (player.stair < 120) {
            return player.battleStair * 0.95;
        } else {
            return player.battleStair * 0.9;
        }
    }

    private static getRedStairByPlayerStair(player: Player) {
        if (player.stair <= 25) {
            return player.stair + 1;
        } else if (player.stair < 60) {
            return player.stair;
        } else if (player.stair < 100) {
            return player.battleStair + 1;
        } else if (player.stair < 120) {
            return player.battleStair + 1;
        } else {
            return player.battleStair + 1;
        }
    }

    public static removeBracketsAndCurlyBraces(input: string): string {
        return input.replace(/\[.*?\]/g, '').replace(/\{\{.*?\}\}/g, '');
    }

    public static replacePlayerName(text: string, playerName: string): string {
        return text.replaceAll(PLAYER_NAME_PLACEHOLDER, playerName);
    }

    public static replacePronounsByPlayerGender(text: string, gender: Gender): string {
        const pronounTo = (() => {
            switch(gender) {
              case Gender.male: return Tr('to.player.him');
              case Gender.female: return Tr('to.player.her');
            }
          })();

          const pronounFor = (() => {
            switch(gender) {
              case Gender.male: return Tr('for.player.him');
              case Gender.female: return Tr('for.player.her');
            }
          })();

          return text.replaceAll(TO_PLAYER_PLACEHOLDER, pronounTo).replaceAll(FOR_PLAYER_PLACEHOLDER, pronounFor);
    }

}

export const enum TextAlign {
    CENTER = "center",
    LEFT = "left",
    RIGHT = "right"
}

export const enum FontsKeys {
    COINY = 'Coiny',
}