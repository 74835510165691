import {Tr} from "@/i18n/i18n";
import {EffectType, GroupInfo} from "@/store/artifact/types";
import Scene = Phaser.Scene;
import {Creature} from "@/store/level/types";
import PlayerStore from "@/store/player";
import SkillUtils from "@/utils/skill-utils";
import {StringUtils} from "@/utils/string-utils";
import {ListBlackouter} from "@/utils/list-blackouter";
import ArtifactStore from "@/store/artifact";
import {TextPanel} from "@/scenes.ui/text-panel";
import {ArtLevelLine} from "@/scenes.ui/art-level-line";
import {ListService} from "@/services/list-service";
import Container = Phaser.GameObjects.Container;

export default class ArtifactUtils {

    public static readonly ARTIFACTS_RANGE_TO_LOAD = 3;

    public static loadArtifacts(scene: Scene): void {
        for (let a of PlayerStore.getArtifactIdsToLoad()) {
            if (!scene.textures.exists(a.atlasId)) {
                scene.load.atlas(a.atlasId, `assets/artifacts/${a.atlasId}.png`, `assets/artifacts/${a.atlasId}.json`);
            }
        }
        PlayerStore.SET_NEED_DOWNLOAD_IMAGES(false);
    }

    public static loadArtifactsByCreatures(scene: Scene, creatures: Creature[]): void {
        creatures.forEach(c => {
            c.artifacts.forEach(a => {
                if (!scene.textures.exists(a.atlasKey)) {
                    scene.load.atlas(a.atlasKey, `assets/artifacts/${a.atlasKey}.png`, `assets/artifacts/${a.atlasKey}.json`);
                }
            })
        })
    }

    public static getEffectText(effectType: EffectType, effectValue: number): string {
        return StringUtils.formatString(this.doGetEffectTypeText(effectType), SkillUtils.formatMoreThanMillion(effectValue));
    }

    public static getEffectTypeText(et: EffectType): string | undefined {
        switch (et) {
            case EffectType.ATTACK:
                return Tr('ataka');
            case EffectType.DEFENCE:
                return Tr('zaschita');
            case EffectType.ATTACK_DEFENCE:
                return Tr('ataka.i.zaschita');
            case EffectType.HEALTH:
                return Tr('zdorove');
        }
        return '?';
    }

    public static doGetEffectTypeText(et: EffectType): string {
        switch (et) {
            case EffectType.ATTACK:
                return Tr('eff.att');
            case EffectType.DEFENCE:
                return Tr('eff.def');
            case EffectType.ATTACK_DEFENCE:
                return Tr('eff.a.d');
            case EffectType.HEALTH:
                return Tr('eff.hl');
            case EffectType.KOSCHEY_ATTACK:
                return Tr('eff.k.att');
            case EffectType.KOSCHEY_DEFENCE:
                return Tr('eff.k.def');
            case EffectType.POISON:
                return Tr('eff.pois');
            case EffectType.RESURRECTION:
                return Tr('eff.res');
            case EffectType.AMULET_INKUB:
                return Tr('eff.ink');
            case EffectType.AMULET_SUKKUB:
                return Tr('eff.suk');
            case EffectType.AMULET_WITCH:
                return Tr('eff.wit');
            case EffectType.AMULET_HAMMER:
                return Tr('eff.ham');
            case EffectType.SHADOW_ENFORCE:
                return Tr('eff.enf');
            case EffectType.AMULET_GORGON:
                return Tr('eff.gor');
        }
        return '?';
    }

    public static getEffectTypeGenetive(et: EffectType): string {
        switch (et) {
            case EffectType.ATTACK:
                return Tr('ataki');
            case EffectType.DEFENCE:
                return Tr('zaschiti');
            case EffectType.ATTACK_DEFENCE:
                return Tr('ataki.i.zaschiti');
        }
        return '';
    }
}
