export const EN = {
    'blocked': 'User is not available for contact',
    'ke': '',
    'golem': 'GOLEM',
    'troyke': 'TRIO',
    'voidite.v.top.n.i.polu4ite': 'Reach the top-{0} and receive',
    'empty.nm': 'The name cannot be empty!',
    'etot.slot.poka.ne.dostupen': 'This slot is not available yet.',
    'v.komandnom.zadanii.uchastvujut.vse.chleni.klana.n': '* All members of the clan participate in the team mission\n',
    'povisit.do.uchastnika': 'PROMOTE TO A MEMBER',
    'otpravitsja.v.snezhniy': 'You can go to the snowy world no more often than once per ',
    'vvedite.imja': ' ENTER NAME',
    'vvedite.nazvanie': ' ENTER NAME',
    'iz.nih.stsenarnih': 'INCLUDING SCRIPTED:',
    'dostizhenija': 'ACHIEVEMENTS:',
    'vvedite.tekst': 'ENTER TEXT...',
    'postroyki': 'BUILDINGS',
    'povisit.do.zam.lidera': 'PROMOTE TO DEPUTY LEADER',
    'mi.nashli.i.sobrali.resursi.eto.ochen.horosho': 'We\'ve started collecting resources. Well done! Now, let\'s continue our adventures!',
    'ur.large.dot': 'LVL.',
    'legkoe': 'EASY',
    'nachalo.igri': 'REGISTERED: ',
    'izmeneno': 'Value changed',
    'iskat': 'SEARCH',
    'posle.voskreshenija.trizhdi.atakuet.obidchika': '-AFTER RESURRECTION ATTACKS THE ABUSER THREE TIMES',
    'vi': 'You ',
    'viigrano.bitv': 'WINNING BATTLES:',
    'zdorove.vosstanovleno': 'Health is recovered',
    'ur.dot': 'Lvl. ',
    'vosstanovit.zdorove.vsem.gerojam': 'RECOVER THE HEALTH OF ALL THE CHARACTERS?',
    'v.magazin': 'To the store',
    'kristalli': 'Gems',
    'er.no.nm': 'You have to enter some nickname!',
    'er.no.ml.or.phn': 'You have to enter some email or phone!',
    'zvuk.vkljuchen': 'SOUND IS ON',
    'po.kubkam': 'By cups',
    'by.arena.position': 'By arena',
    'vot.bi.posmotret.chto.v.etom.sunduke': 'Oh no! The chest is blocked by those wild plants! We need to get rid of them. Click on them to fight!',
    'eliksir': 'Elixir',
    'ne.uchastvuet.v.boju': 'Not in the battle',
    'registratsija': 'SAVE GAME',
    'takzhe.obratite.vnimanie.na.moment': 'Take note also: at the time of the start, the numbers can slightly change depending on the level and number of participants.',
    'kolichestvo.shagov.v.snezhnom.mire.ogranicheno.n': 'Number of steps in the snow world is limited.\n',
    'snachala.zavershim.obuchenie': 'First, let\'s complete the training',
    'k.klanu': 'TO THE CLAN',
    'uyti.v.otstavku': 'STAND DOWN',
    'besplatnoe.otkritie.slota.v.podarok': 'FREE SLOT OPENING AS A GIFT!',
    'skidka.na.pervogo.geroja': 'Discount on the first character!',
    'sobrano': 'Assembled ',
    'ustavshiy.magkuznets.proiznosit': ' The tired blacksmith magician pronounced: Alas, my strength has run out! Find another blacksmith.',
    'voyti': 'Log in',
    'otlichno.mozhem.podozhdat.sekund.ili.uskorit.i.ne.zhdat.': 'Great! We can wait 15 seconds or speed up and don\'t wait.',
    'nekromant': 'Necromancer',
    'close': 'Close',
    'back': 'Back',
    'name.validation': 'The title cannot be empty.',
    'name.length.validation': 'The title cannot be longer than 40 characters.',
    'text.empty.validation':'Text cannot be empty.',
    'text.length.validation':'The comment text cannot be longer than 3000 characters.',
    'topic.length.validation':'Topic text cannot be longer than 20,000 characters.',
    'v.vashem.klane.maksimalnoe.kolichestvo.uchastnikov': 'Your clan has the maximum number of members. Increase the clan level to invite more people.',
    'dobro.pozhalovat.na.rinok': 'Welcome to the market! You can buy red and blue crystals here.',
    'kapituljatsija': 'CAPITULATION',
    'neobhodimo': 'NEED',
    'minute': 'm',
    'dop.uron.cheloveku': '- ADD. DAMAGE TO A HUMAN',
    'dzhinn': 'JEANNE',
    'nm.long': 'The title is a bit long. Max symbols: ',
    'no.mon': 'Not enough funds to buy',
    'otmena': 'CANCEL',
    'comma.zoloto': ', gold: ',
    'initsializatsija.proshla.uspeshno': 'Initialization was successful',
    'vi.ushli.v.otstavku': 'You have resigned',
    'poluchit.seychas': 'GET NOW',
    'pochta': 'MAIL',
    'vi.smozhete.v.ljuboy.moment.izmenit.sostav.geroev': 'YOU CAN CHANGE THE COMPOSITION OF THE CHARACTERS INVOLVED IN THE BATTLE AT ANY TIME',
    'smenit.besplatno': 'Change for free?',
    'doplatit': 'Pay extra',
    'vse.sloti.zanjati': 'ALL SLOTS ARE FILLED',
    'zavidev.vas.kuznetsmag.ozhivljaetsja': 'Seeing you, the blacksmith-magician comes to life and says: Welcome to my forge!',
    'ataki': 'attacks',
    'uroven': 'LEVEL ',
    'ur.': 'LVL ',
    'horosho': 'GOOD',
    'ok': 'OK',
    'zaschitu': 'Defense',
    'vzjat': 'TAKE',
    'zoloto': 'Gold',
    'priobretenie.sputnika': 'SATELLITE PURCHASE',
    'priobretenie.sputnikov': 'SATELLITES PURCHASE',
    'otlichno.geroy.poluchit': 'Excellent, the character will get ',
    'dlja': 'FOR',
    'igrok.povishen.do.predstavitelja': 'Player promoted to representative',
    'odin.iz.starih.geroev.budet.iskljuchen.iz.otrjada': 'ONE OF THE EARLY CHARACTERS WILL BE EXCLUDED',
    '4ast.budet.iskl': 'SOME OF THE EARLY CHARACTERS WILL BE EXCLUDED',
    'nam.predstoit.issledovat.etot.mir': 'We have to explore this world, fight and survive.',
    'reyting.klanov': 'CLAN RATING',
    'za': 'For ',
    'kazna': 'TREASURY',
    'sila.izuchena': 'The power is studied!',
    'sila.uluchshena': 'The class is upgraded!',
    'moi.geroi': 'MY CHARACTERS',
    'povorot': 'Turn',
    'izumrud': ' gem',
    'izumruda': ' gems',
    'iskljuchen.iz.klana': 'Excluded from the clan',
    'reyting': 'Rating',
    'prizy': 'Prizes',
    'place': 'Place',
    'places': 'Places',
    'vi.na.{0}.meste': 'You are at {0} place',
    'unique.hero.1': 'WEAK UNIQUE HERO',
    'unique.hero.2': 'AVERAGE UNIQUE HERO',
    'unique.hero.3': 'STRONG UNIQUE HERO',
    'razblokirovat': 'Unblock',
    'er.ps.empt': 'Uh-oh! The password cannot be blank',
    'er.enter.valid.mail.or.phone': 'Phone must begin with a + sign, mail must contain an @ sign',
    'er.invalid.phone': 'Invalid phone number',
    'er.invalid.mail': 'Invalid mail address',
    'vibor.geroja': 'CHOOSE A CHARACTER',
    'krasniy.kristall': ' red crystal',
    'sredstva.za.postroyki.vzimajutsja.iz.bjudzheta.klana': '*Funds for constructions are charged from the clan budget.',
    'snizh.zaschiti.vraga.pri.udarah': '-DECREASE THE ENEMY\'S PROTECTION ON STRIKES',
    'dostignite': 'GAIN ',
    'siniy.kristall': 'blue crystal',
    'igrok.ponizhen.do.uchastnika': ' Player demoted to member',
    'dlja.pokupki.ne.hvataet': 'To buy, there is not enough',
    'v.snezhniy.mir': 'Into the snow world',
    'takzhe.vi.mozhete.pereyti.k.reytingu.klanov': 'You can also go to the rating of clans and apply to your favorite clan. Or create your own clan.',
    'net.svobodnogo.mesta.dlja.artefakta': 'No free space for artifact',
    'tsena.za.edinitsu': 'Price per unit',
    'vas.priglashajut.v.klan': 'You are invited to the clan',
    'umensh..uron.vragamkrome.nekr.': '-REDUCED DAMAGE TO ENEMIES (EXCEPT NECR.)',
    'artefakt.na.maksimalnom.urovne': 'THE ARTIFACT IS AT THE MAXIMUM LEVEL',
    'sila.na.maksimalnom.urovne': 'THE FORCE HAS A MAXIMUM RANK',
    'dar.poka.ne.dostupen': 'THE FORCE IS NOT AVAILABLE YET',
    'you.br': '(YOU)',
    'your.clan.br': '(your)',
    'clan.history': 'Clan history',
    'otlichno.ah.chut.ne.propustil.eta.kuchka.s.kristallami.nam.tozhe.nuzhna': 'That\'s great! Look, there\'s something else next to it. A bunch of crystals! Let\'s grab them too.',
    'uskorit': 'Accelerate',
    'zver': 'Beast',
    'ch.m.12.59': '12H. 59M.',
    'ne.hvataet.izumrudov': 'Not enough gems!',
    'snachala.nuzhno.chtoto.vnesti': 'First you have to put something in',
    'postroyki.klana': 'CLAN’S CONSTRUCTIONS',
    'smotri.mi.nashli.zoloto': 'Look, we found gold! Let\'s get it, I\'m sure it\'ll come in handy.',
    'vi.pitaetes.smenit.pol.vashego.personazha': 'You are trying to change the gender of your character',
    'username': 'username',
    'ne.zhdat.i.poluchit': ' Don\'t wait and get ',
    'darim.vam.ezhednevniy.podarok': 'DAILY GIFT',
    'ur.klassa': ', LVL. CLASS: ',
    'minut.30': '30 minutes',
    'nabirayte.opit.i.poluchayte.k.zdorovju': ' Gain experience, and get +{0} to health for each level',
    'krasn.krist.': 'Red crystal',
    'izuchit': 'STUDY',
    'chelovek': 'HUMAN',
    'magazin.geroev.dostupen.s': 'The character shop is available from',
    'demon': 'DEMON',
    'peremeschenie': 'TRANSFER',
    'chto.takoe.klan': 'What is a clan?',
    'viberite.na.kogo.zamenit': 'CHOOSE WHO TO REPLACE HIM WITH',
    'day.dot': 'd. ',
    'start.zadanija': 'START OF THE MISSION: ',
    'soberite': 'GATHER',
    'snizhenie.ataki.protivnika': '-Decreasing the opponents\' attack',
    'igrok.prigleshen.v.klan': 'The player is invited to the clan',
    'vremja.vipolnenija': 'execution time: ',
    'stsenarnoe': 'scripted',
    'sidequest': 'sidequest',
    'event': 'event',
    'viberi.komu.peredat.predmet': 'SELECT WHOM TO GET THE ITEM',
    'vosstaet.posle.smerti': '-RISES AFTER DEATH',
    'tekuschiy': ' (CURRENT)',
    'dari': 'GIFTS',
    'artifacts': 'ARTIFACTS',
    'snizhenie.urona.atakujuschego': '-ATTACKER’S DAMAGE REDUCTION',
    'gotovo': 'Ready',
    'brosili.vizov': 'challenged ',
    'iskljuchit.iz.klana': 'EXPEL FROM THE CLAN',
    'po.sile': 'By strength',
    'clan.po.urovniu': 'By level',
    'snjat.sapogi.skorohodi': 'TAKE OFF SPEED BOOTS',
    'minut.15': '15 minutes',
    'teper.v.otrjade': 'now in the squad!',
    'u.klana.nedostatochno.sredstv': 'The clan has insufficient funds',
    'vihod': 'EXIT',
    'priglasit.v.klan': 'invite to the clan',
    'zdes.igroki.srazhajutsja.drug.s.drugom': 'Here the players fight with each other.',
    'na.arenu': 'TO THE ARENA!',
    'viberi.kto.iz.geroev.poluchit.predmet': 'Select which of the characters will receive the item',
    'vstupit': 'ENTER',
    'zaschiti': 'protection',
    'profil': 'PROFILE',
    'geroi': 'HEROES',
    'cards': 'CARDS',
    'sozdat.klan': 'CREATE A CLAN',
    'dobivaniy': 'kills',
    'porazhenie': 'DEFEAT',
    'pobeda': 'VICTORY',
    'nashi.geroi.raneni.v.bojah': 'OUR CHARACTERS WERE WOUNDED IN THE BATTLES',
    'v.profil': 'TO PROFILE',
    'deystvija': 'ACTIONS',
    'improve.creature.classes': 'Improve creature classes',
    'gotovi.priobresti.sputnika': 'READY TO PURCHASE A SATELLITE?',
    'obnovit': 'UPDATE',
    'arena': 'ARENA',
    'change': 'change',
    'spets.predlozhenie': 'SPECIAL OFFER!',
    'uluchshit': 'Improve',
    'explore': 'Explore',
    'vkljuchit.zvuk': 'TURN SOUND ON',
    'klan': 'CLAN: ',
    'hint': 'HINT',
    'hint2': 'HINT',
    'hint.unavailable': 'Hint unavailable',
    'delete.hint': 'Delete hint',
    'klana': 'OF THE CLAN',
    'noviy.geroy': 'NEW TEAMMATE',
    'zadanie.vipolneno': 'THE MISSION IS DONE',
    'dialog': 'DIALOG',
    'supp.info': 'This message is for the Technical Support service of the game "Mirrorland". Please describe your problem or question in detail.',
    'izuchaem.silu': 'LEARNING POWER',
    'nagradi.bracket': 'REWARDS (',
    'vibrat': 'SELECT',
    'v.rodnoy.mir': 'TO THE NATIVE WORLD',
    's.vozvrascheniem': 'WELCOME BACK!',
    'ref.welcome.header': 'welcome!',
    'ref.welcome.info': 'welcome bonus',
    'ref.welcome.label': 'For registering with a referral link',
    'ref.bonus.header': 'Bonus',
    'ref.bonus.info': 'Referral bonus',
    'ref.bonus.label': 'For referring friends to the game',
    'ref.bonus.comment': 'Also your clan receives',
    'v.boy': 'FIGHT!',
    'hochu.v.klan': 'I WANT TO THE CLAN!',
    'povisit.do.predstavitelja': 'PROMOTE TO A REPRESENTATIVE',
    'poluchaem': 'GETTING',
    'zavershit': 'complete',
    'vikljuchit.zvuk': 'turn off sound ',
    'dop.uron.zverjam': '-ADD. DAMAGE TO BEASTS',
    'artefakt.uspeshno.perekovan': 'Artifact successfully recast',
    'terjaet': 'loses ',
    'uluchshit.do.urovnja': 'Improve to level ',
    'uluchshit.do.ranga': 'Improve to rank ',
    'podrobnee.o.rangah': 'more about ranks',
    'explore.new.force': 'Explore new force',
    'novoe': 'NEW',
    'zamena.geroja': 'character substitution',
    'postroit': 'BUILD',
    'details': 'DETAILS',
    'rub': 'RUB.',
    'rub.small': 'rub',
    'prigoditsja.dlja.uluchshenija': 'COMES IN HANDY FOR IMPROVING',
    'dlja.vas.teper.dostupna.prokachka.klassa.geroja': 'You can now pump up the class of the character!',
    'ponizit.do.predstavitelja': 'DOWNGRADE TO REPRESENTATIVE',
    'zmey': 'SNAKE',
    'zvuk.vikljuchen': 'SOUND IS OFF',
    'ne.hvataet.izumrudov.kupite.v.banke': 'Not enough gems? Buy them in the bank!',
    'podat.zajavku': 'SUBMIT APPLICATION',
    'novichok': 'Newbie',
    'novaja': 'new',
    'poluchit.nagradu': 'Claim',
    'y.uroven': 'level',
    'y.uroven.monstra': 'monster level',
    'sapogiskorohodi.snjati': 'The speed boots are off',
    'bank.izumrudov.dostupen.s': 'Bank of gems available from ',
    'brac.boev': '),   FIGHTS: ',
    'summarniy.prirost': 'SUMMARY GROWTH',
    'prirost': 'GROWTH',
    'skidki.na.vseh.geroev.v.magazine': 'ALL CHARACTERS IN THE STORE ARE DISCOUNTED!',
    'personazh.polnostju.zdorov': 'The character is completely healthy',
    'do.kontsa': 'UNTIL THE END: ',
    'skidki.na.vseh.geroev': 'All characters on sale!',
    'seychas': '(now',
    'vibrano': ' (selected) ',
    'postroyka.gotova': 'THE CONSTRUCTION IS READY',
    'neobhodimo.poluchit': 'Necessary to get ',
    'zdorove.geroev.vremenno.umenshaetsja': 'CHARACTER\'S HEALTH IS TEMPORARILY REDUCED',
    'zaschita.klana': 'PROTECTION OF THE CLAN',
    'er.nm.shrt': 'Mmm... The nickname is a bit short.',
    'dop.uron.jascheram': '-ADD. DAMAGE TO LIZARDS',
    'zaschita.plus': 'Defense +',
    'otlichno.schit.teper.daet.k.zaschite': 'Excellent! The shield now gives +3 to protection. Let\'s get back to the road.',
    'dop.uron.dzhinnam': '- ADD. DAMAGE TO JEANNES',
    'k.doroge': 'ROAD',
    'nagrad.poka.net': 'NO AWARDS YET',
    'popolnenija.kr..kristalli': 'Replenishments. Red crystals: ',
    'komandnoe.zadanie.segodnja': 'TEAM ASSIGNMENT TODAY',
    'and': 'and',
    'zazerkale.mir.geroev.i.volshebnikov.monstrov.i.tayn.': 'The Mirrorland is a world of sorcerers, monsters and mysteries.',
    'slot': 'SLOT',
    'protivnik': 'Opponent',
    'change.of.your.position': 'Change of your position: ',
    'ataka.i.zaschita': 'Attack and protection',
    'cherez': 'IN ',
    'smenit': 'CHANGE',
    'zadat': 'SET UP',
    'istorija.areni': 'HISTORY OF THE ARENA',
    'vashi.geroi': 'YOUR HEROES',
    'ligi.areni': 'LEAGUES OF THE ARENA',
    'players.on.positions': 'Players on places',
    'liga.1': 'Novice League',
    'liga.2': 'Wooden League',
    'liga.3': 'Stone League',
    'liga.4': 'Bronze League',
    'liga.5': 'Silver League',
    'liga.6': 'Gold League',
    'liga.7': 'Diamond League',
    'liga.8': 'Master League',
    'liga.9': 'Champions League',
    'daily.arena.reward': 'Daily arena reward for',
    'nagrada.za.sobitie': 'Reward for event',
    'nagrada.za.sobitie.nachislena': 'You received a reward for participating in the event:',
    'arena.info.1': 'Win on the arena and take the opponent place',
    'arena.info.2': 'All amplifiers are active on the arena, except for the Elixir of persuasion. Also Phoenix Terror does not affect Asgardian Phoenixes',
    'arena.info.3': 'Get a reward daily at {0} local time; next reward in ',
    'arena.info.4': 'The higher the place, the greater the reward!',
    'arena.info.5': 'If after the victory your position has not changed, it means the attacked player fought earlier',
    'arena.reward.time.info': '* The time till reward may be increased',
    'current.league': 'Current league',
    'best.league': 'Best league',
    'current.position': 'Current position',
    'best.position' : 'Best position',
    'position' : 'Position',
    'position.in.rating' : 'Position in rating',
    'o.klane': 'ABOUT THE CLAN',
    'brosil': 'quit ',
    'nedavno.ja.poluchil.poslanie.ot.belogo.krolika': 'Not so long ago I received a message from the white rabbit. He called for help, we need to find him as soon as possible.',
    'one.chas': '1 hour',
    'bonusi': 'BONUSES:',
    'stoimost.postroyki': 'CONSTRUCTION COST: ',
    'kontrataka': '-COUNTERATTACK',
    'do.kontsa.predlozhenija': 'TO THE END OF PROPOSAL',
    'ataki.i.zaschiti': 'attacks and protections',
    'sled.ur.bracket': ' (NEXT. LVL. ',
    'zaversheno.uluchshenie': 'IMPROVEMENT COMPLETED',
    'zaversheno.uluchshenie.dara': 'FORCE IMPROVEMENT COMPLETED',
    'teper.vladeet': 'now has ',
    'teper.vladeut': 'now have ',
    'novim.darom': 'a new force.\n Way to go!',
    'novimi.darami': 'new forces.\n Way to go!',
    'zaplatit.za.nih.mozhno.zolotom': 'You can pay for them with gold or crystals. One for the other. Shall we try?',
    'one.den': '1 day',
    'uvi.sloti.vseh.ostalnih.geroev.zanjati': 'ALAS, THE SLOTS OF ALL THE OTHER CHARACTERS ARE TAKEN!',
    'vipolneno': 'Completed: ',
    'ataka': 'ATTACK',
    'uspey.kupit': 'HURRY TO BUY!',
    'maks.ur': 'MAX. LVL.: ',
    'predstavitel': 'Representative',
    'igrok.ponizhen.do.predstavitelja': 'The player demoted to representative ',
    'vi.uvereni.chto.hotite.sdatsja': 'are you sure you want to surrender?',
    'atak.dvazhdi.vtoraja.atk.sluch.vragu': '-twice ATTACK. SECOND attack to a random enemy',
    'slozhnost.skorrektirovana': 'Difficulty is corrected',
    'er.ps.n.mtch': 'Oops! The entered passwords do not match',
    'posledniy.vhod': ' LAST ENTRY: ',
    'spisok.pust': 'THE LIST IS EMPTY',
    'pusto': 'EMPTY',
    'smena.pola': 'GENDER CHANGE',
    'da': 'YES!',
    'net': 'NO',
    'vosstanovit': 'RECOVER',
    'takim.obrazom.za.boy.mozhno.poluchit': ' This way, you can get a total of up to 6 points per fight, depending on the number of opponents.[/color]\n\n',
    'troll': 'TROLL',
    'skuld': 'SKULD',
    'baltazar': 'BALTHAZAR',
    'sikji': 'SIKJI',
    'smenit.pol': 'CHANGE GENDER',
    'smenit.sposob.oplaty': 'PAYMENT METHOD',
    'smena.sposoba.oplaty': 'CHANGE PAYMENT METHOD',
    'vi.pitaetes.smenit.sposob.oplaty': 'CHOOSE THE APPROPRIATE PAYMENT METHOD',
    'xsolla.label': 'XSOLLA',
    'robokassa.label': 'ROBOKASSA',
    'viberite.sposob.oplaty': 'Choose the appropriate payment method',
    'current.payment.method': 'CURRENT METHOD:',
    'payment.method.recommended': '(RECOMMENDED)',
    'emissar.privetstvuju': 'Emissary: greetings, ',
    'zabanit.igroka': 'BAN A PLAYER',
    'opit.plus': 'EXPERIENCE +',
    'factory.gift.value.plus': 'BASE FACTORY GIFT VALUE +',
    'dop.uron.nekromantam': '-ADD. DAMAGE TO NECROMANCERS',
    'bank': 'BANK',
    'no.msg': 'No messages',
    'smiley.img': 'Smiley image',
    'answer': 'Quote',
    'copy.msg': 'Copy',
    'delete.message': 'Delete',
    'forw.message': 'Forwarded message',
    'max.message.len': 'The maximum message length is 1000 characters.',
    'viberi.geroja.kotoriy.bolshe.tebe.podhodit': 'CHOOSE THE CHARACTER THAT SUITS YOU BEST',
    'ezhednevnoe': 'DAILY',
    'gdpr.title': 'Personalize Your Ad Experience',
    'gdpr.body': 'When you allow this app to show personalized ads, it helps to keep the app free-to-play. The application alos collect and processes your personal information to improve its quality. By clicking ACCEPT button, you agree to these conditions, as well as to the Privacy Policy.',
    'gdpr.accept.button': 'ACCEPT',
    'gdpr.decline.button': 'DECLINE',
    'gdpr.privacy': 'Privacy policy',
    'gdpr.terms': 'Terms of Use',
    'beeline.welcome.title': 'Important information',
    'beeline.welcome.body': 'By clicking Continue, you agree to the terms of the User Agreement',
    'beeline.welcome.accept.button': 'Continue',
    'beeline.payment.confirmation.title': 'Payment confirmation',
    'beeline.payment.confirmation.text': 'The funds will be debited from your mobile balance and credited to your account within a few seconds.',
    'beeline.payment.confirmation.accept_text': 'Ok',
    'beeline.payment.confirmation.decline_text': 'Cancel',
    'beeline.payment.confirm.user.agreement.text': 'By proceeding to payment you agree to the',
    'beeline.payment.confirm.user.agreement.link': 'user agreement',
    'feniks': 'PHOENIX',
    'ponizit.do.uchastnika': 'DEMOTE TO A MEMBER',
    'zahodite.v.igru.zavtra.i.poluchite': 'Come to the Game tomorrow and get ',
    'vremja': 'TIME ',
    'er.ps.lng': 'Password is too long. Maximum ',
    'kupi.izumrudi.i.poluchi..v.podarok': 'BUY GEMS AND GET +100% AS A GIFT!',
    'kupi.izumrudi.i.poluchi..v.podarok2': 'BUY GEMS AND GET +50% AS A GIFT!',
    'eto.svobodniy.slot.dlja.predmeta.': 'This is a free slot for the item.',
    'vozmozhnost.vstupit.v.klan.otkroetsja.s': 'THE OPPORTUNITY TO JOIN THE CLAN WILL OPEN FROM ',
    'to.wheel': 'SPIN WHEEL',
    'to.wheel.free': 'SPIN FOR FREE',
    'zakrit': 'Close',
    'all.emoji': 'All emoji',
    'smiley': 'Smiley',
    'igrok': 'Player: ',
    'igrokk': 'Player',
    'igroka': 'player',
    'five.minut': '5 minutes',
    'uzhe.est.akkaunt': 'ALREADY GOT AN ACCOUNT?',
    'one.minuta': '1 minute',
    'lider': 'Leader',
    'slavnaja.pobeda': 'GLORIOUS VICTORY!',
    'vnesti': 'bring in',
    'gruppa': 'GROUP',
    'otkritie.slota': 'SLOT OPENING',
    'ukazatel': 'DESCRIPTION',
    'sdatsja': 'SURRENDER',
    'noviy.uroven.poluchen': 'New level!',
    'unikalniy.drakon.so.skidkoy': 'Unique dragon on sale!',
    'poka.vas.ne.bilo': 'WHILE YOU WERE OUT',
    'pomogite.svoemu.klanu.uchastvuyte.v.komandnom.zadanii': 'Help your clan. Take part in the team challenge. It can only be done by working together.',
    'standartnoe': 'STANDARD',
    'kupit': 'BUY',
    'spisok.sushestv': 'CREATURES LIST',
    'izumrudi': 'Gems',
    'magazin.predmetov.dostupen.s': 'The item store is available from ',
    'sapogiskorohodi.nadeti': 'Speed boots are on',
    'pobedil': 'won.',
    'prochee': 'OTHER',
    'novaja.pokupka': 'NEW PURCHASE',
    'novoe.zadanie.nachnetsja.zavtra.v': 'new mission starts tomorrow at',
    'predmet.uspeshno.priobreten': 'The item has been successfully purchased!',
    'predmeti.uspeshno.priobreteni': 'Items have been successfully purchased!',
    'priglasit': 'invite',
    'nedostatochno.resursov': 'Not enough resources',
    'nedostatochno.biletov': 'Not enough tickets',
    'use.offer': 'YOU CAN USE SHOP OFFER!',
    'sinie.kristalli': 'Blue crystals',
    'imeetsja': 'available',
    'slozhnost.zadanija': 'Difficulty of the MISSION',
    'uspeshno.uskoreno': 'Successfully accelerated',
    'chat.clana': 'Clan’s chat',
    'ataka.plus': 'ATTACK +',
    'pozhertvovat': 'donate',
    'pokinut.klan': 'LEAVE THE CLAN',
    'luchshaja.pobeda': 'BEST VICTORY:',
    'arena.esche.ne.otkrilas.podozhdite.nemnogo': 'Arena has not opened yet, wait a little...',
    'vidish.eto.schit.kotoriy.mi.nashli': 'See, this is the shield we found. It’s good, but we can improve it also. To do this, click on the shield.',
    'oshibka.initsializatsii': 'Initialization error',
    'v.boju.mogut.uchastvovat.lish': 'ONLY THE FOLLOWINGS MAY TAKE PART IN THE BATTLE ',
    'do.povtornogo.otpravlenija': 'PRIOR TO LEAVING AGAIN:',
    'zaschita': 'Protection',
    'ups': 'WHOOPS',
    'no.forces.to.learn': 'NO FORCES TO LEARN.',
    'no.forces.to.learn2': 'All possible hero forces have already been studied.',
    'ups.warlock': 'A warlock cannot rank up. However, any force is studied immediately by the second rank.',
    'ups.titan': 'Titan cannot improve rank. However, any force is studied immediately by the third rank.',
    'ups.angel': 'An angel cannot improve rank. However, any force is studied immediately by the fourth rank.',
    'ups.archangel': 'An archangel cannot improve rank. However, any force is studied immediately by the fifth rank.',
    'ups.archititan': 'Archititan cannot improve rank. However, any force is studied immediately by the fifth rank.',
    'ups.yotun': 'Yotun cannot improve rank. However, any force is studied immediately by the fourth rank.',
    'ups.asg.phoenix': 'Asgardian phoenix cannot improve rank. However, any force is studied immediately by the fifth rank.',
    'ups.warlock.short' : 'The Warlock does not level up his rank.',
    'ups.titan.short': 'The Titan does not level up his rank.',
    'ups.archititan.short': 'The Archititan does not level up his rank.',
    'ups.angel.short': 'The Angel does not level up his rank.',
    'ups.archangel.short': 'The Archangel does not level up his rank.',
    'ups.yotun.short': 'The Yotun does not level up his rank.',
    'ups.asg.phoenix.short': 'The Asgardian Phoenix does not level up his rank.',
    'kubkov.na.arene': 'PLACE IN THE ARENA:',
    'fraction.points': 'FRACTION POINTS:',
    'uchastnik': 'Member',
    'pobedili.dot': 'won.',
    'uchastnikov': 'Members: ',
    'level.braket': ' (lvl. ',
    'podobrat': 'PICK UP',
    'dial.l': 'DIALOGS LIST',
    'nm.short': 'Mmm... The title is a bit short.',
    'nagrada.poluchena': 'The reward is received',
    'nagrada': 'REWARD ',
    'drakon': 'DRAGON',
    'vash.hod': 'YOUR TURN',
    'otsutstvujut': '-NOT available',
    'factory.info':     'PRODUCES DAILY GEARS (CHANCE 30%) OR RANDOM RESOURCES (CHANCE 70%) FOR CLAN MEMBERS.',
    'hall.info':        'ALLOWS CLAN TO PUMP CERTAIN CLASSES OF HEROES.',
    'zaschischaet.soklanovtsev.v.bojah.otrazhaja.chast.udarov': 'PROTECTS CLANSMEN IN BATTLES, REFLECTING SOME OF THE HITS.',
    'opit': 'Experience:',
    'good.points': 'Order points',
    'evil.points': 'Shadow points',
    'tickets': 'Tickets',
    'a.teper.vozmem.revansh.dumaju.teper.to.mi.pobedim.etih.monstrov.': 'And now we can take our revenge. I\'m sure we can beat these monsters!',
    'izmenenija.vstupjat.v.silu.so.sledujuschego.zadanija.': '*The changes will take effect from the next mission. ',
    'dlja.uluchshenija.neobhodim': 'For improvement is necessary',
    'dlja.uluchshenija.neobhodima.pobeda': 'To improve, you need to defeat a monster with level ',
    'hero.can.improve.forces.up.to.rank': 'All forces can be improved up to rank ',
    'vse.molotki.zaniati': 'All the magic hammers are busy making improvements!',
    'viberite.vremja.na.kotoroe.hotite.zabanit.igroka': 'Select the time for which you want to ban the player',
    'igrok.ponizhen.do.novichka': 'The player is demoted to newbie',
    'konets.puti': 'THE END OF THE WAY',
    'nanosit.uron.atakujuschemu': '-DEALS DAMAGE TO THE ATTACKER',
    'igrok.povishen.do.uchastnika': 'The player is promoted to a member',
    'magazin': 'SHOP',
    'zadanija': 'MISSIONS',
    'dalee': 'NEXT',
    'kuznitsa': 'SMITHY',
    'vpered': 'go ahead!',
    'viberite.deystvie.': 'Select Action.',
    'a.poka.davay.ne.budem.terjat.ni.minuti': 'For now let\'s not waste any minute and let\'s get going! I\'ll show you everything around.',
    'minute.dot': 'm.',
    'vi.bolshe.ne.sostoite.v.klane': 'You are no longer in the clan.',
    'etot.slot.mozhno.razblokirovat': 'THIS SLOT CAN BE UNLOCKED',
    'peredat': 'Transmit',
    'viberi.kto.iz.geroev.poluchit.bonus': ' Select which of the characters will get a bonus',
    'zdorove': 'Health',
    'mifril': 'Mithril',
    'write.message': 'Write...',
    'dobro.pozhalovat.na.arenu': 'welcome to the arena!',
    'a.teper.nazhmi.uluchshit.': 'Now click "Improve".',
    'zajavka.na.vstuplenie.v.klan.podana': 'Application to join the clan has been submitted',
    'klan.otkroet.novie.vozmozhnosti': 'A clan will open up new opportunities and give you in-game bonuses. A clan is its own people who will come to your aid. Finally, a clan is all about communication and friends.',
    'dop.uron.vsem.vragam': '-ADD. DAMAGE TO ALL ENEMIES',
    'razblokirovat.slot': 'UNLOCK THE SLOT?',
    'dlja.uluchshenija.nuzhna': 'To improve you need ',
    'doplatit.izumrudami': 'PAY EXTRA WITH GEMS?',
    'sinie.krist': 'Blue crystal',
    'vibranniy.artefakt.budet.razobran': 'The selected artifact will be disassembled. Next, we will choose a character who gets 25% of the power of the artifact',
    'smenit.imja': 'CHANGE NAME',
    'email.phone': 'EMAIL/PHONE',
    'pervaja.smena.oboydetsja.besplatno': 'The first change costs free. All subsequent gender changes will cost ',
    'po.urovnju': 'By level',
    'po.shliapam': 'By hats',
    'po.paporotnikam': 'By fern',
    'sbor.shliap': 'Hats collection',
    'sbor.paporotnika': 'Fern collection',
    'nechego.obmenivat': 'Nothing to exchange',
    'podtverzhdenie': 'APPROVAL',
    'esche': 'MORE ',
    'vremja.uluchshenija': 'IMPROVEMENT TIME',
    'vremja.izuchenia': 'EXPLORATION TIME',
    'igrok.povishen.do.zam.lidera': 'The player is promoted to a deputy leader.',
    'a.teper.viberem.geroja.kotoriy.poluchit.': ' AND NOW LET\'S CHOOSE A CHARACTER WHO WILL GET +',
    'shans.otrazit.atk.': '-50%. CHANCE TO REPEL AN ATTACK',
    'kogda.geroy.naberet': '[color',
    'snachala.viberite.artefakt.dlja.perekovki.': 'Select the artifact to be recast first.',
    'one.nedelja': '1 week',
    'obmenjat': 'EXCHANGE',
    'radi.vas.videt': 'GLAD TO SEE YOU',
    'proigrali.nichego.chtobi.luchshe.srazhatsja.davay.podberem.etot.schit': 'Oh no, we lost! It\'s all good. Look! There\'s a shield, let\'s take it, it  will help us.',
    'rinok': 'MARKET',
    'izza.nizkogo.atmosfernogo.davlenija': 'Because of the low atmospheric pressure, you can\'t stay in the world of snow for too long. It\'s time to go home. We can be back in 6 hours.',
    'snizh.zaschiti.atakujuschego': '-DECREASE AN ATTACKER\'S PROTECTION',
    'ne.hvataet': 'NOT ENOUGH',
    'otdaem': 'GIVE',
    'luchshaja.pobeda.ur': 'Best victory: lvl. ',
    'toward': 'toward',
    'krasnie.kristalli': 'Red crystals',
    'dalshe': 'Next',
    'rekruting': 'RECRUITING',
    'informatsija': 'INFORMATION:',
    'klan.eto.obedinenie.igrokov': ' Clan is a union of players in order to pass the game easier and more interesting.',
    'zdorove.cherez.min': 'HEALTH +1 IN 1 MIN',
    'zdorove.cherez': 'HEALTH +1 IN ',
    'mozhno.ispolzovat.v.boju': 'can be used in combat',
    'ispolzovat': 'ACTIVATE',
    'chat': 'CHAT',
    'vi.pokinuli.klan': 'You left the clan',
    'jascher': 'LIZARD',
    'maks.dot': 'MAX.',
    'napisat': 'WRITE',
    'novaja.nahodka': 'NEW FIND',
    'igrok.izgnan.iz.klana': 'Player expelled from the clan',
    'vi.poluchaete': 'YOU GOT',
    'iskljuchen': 'Excluded',
    'vkljuchit.v.otrjad': 'Include in the squad',
    'nadet.sapogi.skorohodi': 'PUT ON SPEED BOOTS',
    'vi.poluchaete.noviy.uroven': 'You got a new level!',
    'kto.budet.luchshim.iz.luchshih': 'WHO WILL BE THE BEST OF THE BEST?',
    'popolnenie.kazni': 'TREASURY REPLENISHMENT',
    'privet.ja.cheshirskiy.kot.i.tvoy.verniy.kompanon.v.zazerkale': 'Hey there! I\'m a Cheshire cat - your faithful friend in the Mirrorland.',
    'kazna.popolnena': 'THE TREASURY IS REPLENISHED',
    'hochet.v.ljuboy.klan': ' Wants to join any clan ',
    'dot.no.povorot.zaschischajut': '. BUT THE TURN IS PROTECTED BY ',
    'smenit.za': 'Change for ',
    'predmeti': 'ITEMS',
    'podderzhka': 'SUPPORT',
    'otlichno': 'Excellent',
    'navsegda': 'Forever',
    'po.aktsii': 'special offer',
    'nagrada.klanu': 'CLAN’S REWARD: ',
    'uvelichivaet.opit.poluchaemiy.za.boy.vsem.uchastnikov.klana': 'INCREASES THE EXPERIENCE GAINED PER BATTLE, TO ALL MEMBERS OF THE CLAN.',
    'zamestitel.lidera': 'Deputy leader',
    'chlen': 'Member',
    'y.uroven.prjamo.seychas': 'level right now',
    'uchastniki': 'MEMBERS',
    'vizov': 'challenge ',
    'proigral': 'lost.',
    'otpravit': 'SEND',
    'slozhnoe': 'DIFFICULT',
    'prinjat': 'ACCEPT',
    'viberite.deystvie': 'SELECT AN ACTION',
    'po.prohozhdeniju': 'By passing',
    'zadaniy.vipolneno': 'COMPLETED MISSIONS:',
    'zagruzka': 'LOADING',
    'zadaniy.poka.net': 'NO MISSIONS YET',
    'v.snezhnom.mire': 'In snow world',
    'otlichno.teper.klikni.na.geroja.': 'Awesome! Now click on the character icon.',
    'soobschenie': 'Message',
    'chasov': '12 hours',
    'teper.s.nami': ' IS NOW WITH US!',
    'klass': 'CLASS: ',
    'uvelichte.svoy.otrjad.vplot.do..geroev': 'Increase your squad up to 6 heroes!',
    'ozhidayte.priglashenija.v.klan.': 'Wait for an invitation to the clan.',
    'stroitsja': 'BUILDING',
    'comma.lev': ', lvl. ',
    'izumrudov': ' gems',
    'er.nm.lng': 'Nickname is too long. Maximum',
    'er.nm.same': 'It is your current nickname ',
    'net.bonusov': '(no bonuses)',
    'dop.uron.demonam': '-ADD. DAMAGE TO DEMONS',
    'neverojatnoe': 'unbelievable',
    'kazhdiy.raz.kogda.geroy.dobivaet.vraga': '[size',
    'ustanovite.predpochtitelnuju.slozhnost.zadanija': 'Set the preferred difficulty of the mission in the snow world at your discretion!',
    'sek': ' sec.',
    'zadanie': 'MISSION',
    'ataku': 'Attack',
    'poisk.igroka': 'Player searching',
    'poisk.klana': 'Clan searching',
    'slabosti': 'WEAKS: ',
    'mag': 'MAGICIAN',
    'otpravit.pismo': 'SEND A MESSAGE',
    'ostalos.vremeni': 'TIME LEFT',
    'idet.uluchshenie': 'Improvement in progress',
    'idet.izuchenie': 'Under study',
    'hochet.imenno.v.vash.klan': 'Wants to join your clan',
    'hour': 'H. ',
    'bil.v.igre': 'Was in the Game:  ',
    'ponizit.do.novichka': 'DEMOTE TO NEWBIE',
    'vam': 'You',
    'confirm': 'CONFIRM?',
    'rang': 'RANK',
    'ranga': 'RANK',
    'hero.rang': 'HERO RANK',
    'can.improve.forces': 'CAN IMPROVE FORCES!',
    'force.ranks': 'FORCE RANKS',
    'forces': 'FORCES',
    'reforge': 'ITEMS REFORGE',
    'clan.max.mem': 'CLAN ACHIEVED MEMBERS LIMIT',
    'home': 'TIME TO RETURN',
    'fights': 'FIGHTS',
    'fights.in': 'FIGHTS IN',
    'go.level': '-TH LEVEL',
    'heroes': 'HEROES',
    'items.gen': 'ITEMS',
    'forces.gen': 'FORCES',
    'force.gen': 'FORCE',
    'max': 'MAX.',
    'edit': 'EDIT',
    'block': 'Block',
    'init.txt': 'INITIAL TEXT',
    'force': 'FORCE',
    'rank': 'rank',
    'editing': 'EDITING',
    'save': 'SAVE',
    'clan.cr': 'CLAN CREATION',
    'clan.nm': 'Set up the clan name',
    'ent.nm': 'Enter the name',
    'create': 'CREATE',
    'name': 'NAME',
    'pass': 'PASSWORD',
    'log.in': 'Log in',
    'symbols': 'symbols',
    'inv.nm': 'Enter nick name',
    'inv.ps': 'Enter password',
    'rpt.ps': 'Repeat the password',
    'mail.or.phn': 'Email or phone (optional)',
    'nm.chng': 'NAME CHANGE',
    'mail.or.phn.chng': 'EMAIL OR PHONE',
    'ldng': 'Loading ...',
    'lan': 'LANGUAGE',
    'switch.lang': 'Current language: ENGLISH. Switch to Russian?',
    'select.lang': 'Choosing the language:',
    'logo-class': 'aof-logo-en',
    'gems.mul.gen': 'emeralds',
    'from': 'from',
    'fromC': 'From',
    'discount.used':'Price with discount',
    'to': 'to',
    'lev.gen': 'level',
    'news.rang.title': 'RANK!',
    'news.rang.subtitle': 'PUMPING OF THE CHARACTERS RANK IS ACCESSIBLE!',
    'news.rang.1': 'Every character now gains personal experience. Your character will get a new rank once he gains the right amount of experience',
    'news.rang.2': 'Ranks take a long time to rise. However, those who manage to do that will get enhanced bonuses for their characters.',
    'news.force.title': 'FORCES!',
    'news.force.subtitle': 'GIFTS ARE AVAILABLE TO YOUR CHARACTERS!',
    'news.force.1': 'Every character is now eligible for a gift, a bonus to another class',
    'news.force.2': 'For example, a human who received the gift of the dragon can attack twice, at the same time reducing the enemy\'s defense',
    'news.force.40.title': 'Hero Page',
    'news.force.40.1': 'Welcome to the updated hero page!',
    'news.force.40.2': 'There are more features now, and navigation is more convenient.',
    'news.force.500.1': 'Wow, everything has changed here!',
    'news.force.500.2': 'Artifacts remain familiar.',
    'news.force.500.3': 'But the gifts... Now many heroes can upgrade the ranks of gifts!',
    'news.force.500.4': 'Yes, yes, just like that! We will explain everything now!',
    'news.force.500.5': 'By going to the "Gifts" tab, you will see the hero`s gifts around them - both the studied ones and those that can be studied.',
    'news.force.500.6': 'Gifts that can be studied are marked with a green lock.',
    'news.force.500.7': 'Gifts that can be upgraded are marked with two arrows.',
    'news.force.500.8': 'Remember - upgrading gifts takes time.',
    'news.force.500.9': 'Moreover, only two gifts can be upgraded at the same time.',
    'news.force.500.10': 'So choose wisely and upgrade only the most necessary gifts!',
    'news.force.500.11': 'Different heroes can upgrade gifts to different levels.',
    'news.force.500.12': 'This is where it gets interesting: the hero who can upgrade gifts to a higher rank is now more valuable.',
    'news.force.500.13': 'For example, a human can upgrade gifts to the 5th rank, Asgardian phoenixes to the 6th rank, Rokkara to the 7th rank, and the great Nuk-Serra to the epic 8th rank.',
    'news.force.500.14': 'But enough words, let`s play! Forward, on the great journey!',
    'news.rf.title': 'RANK AND FORCES!',
    'news.rf.subtitle': 'PUMPING OF RANK & GIFTS IS AVAILABLE!',
    'news.rf.1': 'Every character now gets personal experience, and can upgrade his or her rank',
    'news.rf.2': 'A character may also receive a gift of another class. For example, a person receiving the gift of the dragon will be able to attack twice and reduce the enemy\'s defense',
    'forces.are.cool': 'Upgrading your characters with new forces is especially effective, because when you study an additional force, the character gets only the positive aspects of it. For example, learning a necromancer\'s power won\'t make the character more vulnerable to genies. However, only for the main class is available to pump the rank',
    'clan.resign': 'Are you sure you want to stop being a clan leader? Leadership will be given to the most deserving member of your clan',
    'clan.leave': 'Are you sure you want to leave this clan?',
    'do.you.want.to.buy': 'Do you want to buy ',
    'insufficient.emeralds': 'Insufficient emeralds',
    'buy.hammer.agitation': 'Buy a magic hammer, and improve as much as {0} forces at the same time!',
    'buy.hammer.agitation2': 'Buy a magic hammer, and improve as much as {0} forces at the same time!',
    'd.hammer.for': '-d magic hammer for ',
    'th.hammer.for':  '-th magic hammer for ',
    'pokupka.molotka':  'Buy hammer',
    'clan.txt.revert': 'Are you sure you want the original text back? All changes made in the text will be lost',
    'clan.b.started': 'Construction started',
    'clan.b.completed': 'Construction completed',
    'news.p2.title': 'Continuation!',
    'news.p2.subtitle': 'WELCOME TO PART 2!',
    'news.p2.1': 'The sequel of the game is finally ready! The portal is launched, hurry up - to the world of the Sun dragons!',
    'news.p2.2': 'Soon you will meet the turn to the portal. Good luck with the game! Thank you for being with us!',
    'th.place': ' place',
    'hour.dot': 'h.',
    'sec.dot': 's.',
    'rang.1': 'The boost of the characters rank is now available for you!',
    'rang.2': 'Every time a character finishes an enemy, he gets a point of personal experience',
    'rang.3': 'When the character gains the required number of points, he gets a new rank and improved class bonuses (keeping all previous ones intact)',
    'rang.4': 'The rank rises for a long time. However, those who manage to do so will get strong bonuses for their characters',
    'th.rang.now': 'rank now?',
    'of': 'of',
    'pict.not.sup': 'pictures are not supported yet',
    'message.copied': 'Message copied',
    'exp.t.lev': 'experience up to the rank',
    'red.cr.gen': 'red crystals',
    'red.cr.mul.gen': 'red crystals',
    'blue.cr.gen': 'blue crystals',
    'blue.cr.mul.gen': 'blue crystals',
    'gold.gen': 'gold',
    'exit.sure': 'Are you sure you want to exit the game?',
    'moder': 'MODERATOR',
    'admin': 'ADMINISTRATOR',
    'god': 'DEVELOPER',
    'good.fellow': 'GOOD PLAYER',
    'recieves': 'received',
    'challenged.female': 'challenged',
    'you.lost': 'lost',
    'she.lost': 'lost',
    'she.won': 'won',
    'best.v.lev': 'best win: lvl.',
    'clan.exp': 'of experience in the clan',
    'clan.fights': 'fights in the clan',
    'last.ent': 'last login',
    'at.y.service': 'Waiting for your instructions',
    'req.gain': 'required to collect',
    'bl.cryst': 'blue crystals',
    'hrs': 'hours',
    'best.mul.gen': 'BEST',
    'pravila.u.menja.takie.pri.perekovke.vibranniy': 'My rules are the following: the selected artifact disappears when you forge it, and any chosen character gets 25% of the power of the forged artifact',
    'igrok.priglashen.v.klan': 'The player is invited to the clan',
    'beast.gen': 'BEAST',
    'demon.gen': 'DEMON',
    'dragon.gen': 'DRAGON',
    'gin.gen': 'GENIE',
    'golem.gen': 'GOLEM',
    'human.gen': 'HUMAN',
    'lizard.gen': 'LIZARD',
    'elf.gen': 'ELF',
    'mag.gen': 'MAGICIAN',
    'necro.gen': 'NECROMANCER',
    'serpent.gen': 'SERPENT',
    'troll.gen': 'TROLL',
    'phoenix.gen': 'PHOENIX',
    'can.imp': 'can be improved',
    'th.lev.2.batl': ' LEVEL IN ORDER TO FIGHT!',
    'disc.to': 'DISCOUNT UP TO',
    'open.slot.with.disc': 'DISCOUNT FOR SLOT OPENING!',
    'pcnt.for.slot': '% OFF OPENING THE SLOT!',
    'cl.ftrs': 'CLASS FEATURES',
    'your.active': 'YOUR ACTIVE',
    'boosters2': 'POWER UPS',
    'imp.cost': 'IMPROVEMENT COST',
    'bld': 'BUILDING',
    'imp.g': 'IMPROVEMENT',
    'imp': 'IMPROVE',
    'imp.ing': 'IS IMPROVING',
    'reward_x10_for_win.info': 'If you win the next battle, you will receive 10 times more resources',
    'elixir_for_win.info': 'If you win the next battle, you will receive 250 blue crystals',
    'mithril_for_win.info': 'If you win the next battle, you will receive 5 emeralds',
    'attack_for_win.info': 'If the heroes win the next battle, they will receive +10 attack',
    'defence_for_win.info': 'If the heroes win the next battle, they will receive +10 defense',
    'attack-half-to-all.info': 'All heroes lose half attack in the next battle',
    'defence-half-to-all.info': 'All heroes lose half their defense in the next battle',
    'attack-double-to-all.info': 'All heroes get double attack in the next battle',
    'anti-warlock-power.info': 'All heroes get double attack and defence in the next battle',
    'attackAll05.info': 'Attack and defense of all heroes is reduced by 25% (until you break the curse)',
    'defence-double-to-all.info': 'All heroes receive double defense in the next battle',
    'gin_defence.info': 'In the next battle, the crystal of salvation will grant your genie its protection',
    'beast_defence.info': 'In the next battle, the crystal of salvation will grant your beasts its protection',
    'mag_defence.info': 'In the next battle, the crystal of salvation will grant your mages its protection',
    'human_defence.info': 'In the next battle, the crystal of salvation grants its protection to the person',
    'dragon_defence.info': 'In the next battle, the crystal of salvation will grant your dragons its protection',
    'necro_defence.info': 'In the next battle, the crystal of salvation will grant your necromancers its protection',
    'charmMarker.info': 'In the next battle, one enemy creature will fight on your side',
    'charm2.info': 'In the next battle, the opponents will attack each other',
    'blueElvenFlowerMarker.info': 'The power of the Elven Flower gives you 100 defense in the next battle',
    'redElvenFlowerMarker.info': 'The power of the Elven Flower gives you 100 attack in the next battle',
    'yellowElvenFlowerMarker.info': 'The power of the Elven Flower gives you 10 health in the next battle',
    'stealCurseMarker.info': 'Your morale is lower than normal for the next battle',
    'witchLuckMarker.info': 'Spirits sent by the witch help you in the next battle',
    'witchCurseMarker.info': 'Cursed by the witch, you feel very unwell in your next fight',
    'threePowerCone.info': 'The power of the mighty tree doubles the defense of heroes in the next battle',
    'fairyMarker.info': 'The fairy\'s good spell will help you in the next battle',
    'snowflakeMarker.info': 'The winner\'s snowflake weakens your opponent in the next battle',
    'p6tavPotionMarker.info': 'Tavern potion weakens your opponent in the next battle ',
    'cloverMarker.info': 'Luck Clover gives you good luck in your next battle',
    'luckyCat.info': 'Lucky kitty gave you good luck in your next battle',
    'healthPlus3Marker.info': 'Flowers of Immortality grant heroes +3 health in the next battle',
    'kettle.info': 'Dragon Brew grants +10 defense to all heroes in the next battle',
    'healthPlus25.info': 'The magic whirlpool grants +25 health to all your minions in the next battle',
    'summonCopyMarker.info': 'Magic dust summons a copy of your random hero in the next battle',
    'deathCrystal.info': 'The Death Crystal destroys a random enemy in the next battle',
    'idolOfDestruction.info': 'The Idol of Destruction destroys a random hero in the next battle',
    'mushDefence500marker.info': 'The mushroom grants +5000 defense to your random creature in the next battle',
    'mushAttack500marker.info': 'The mushroom grants +500 attack to your random minion in the next battle',
    'serpentForce.info': 'The Serpent Mushroom grants serpent power to a random hero in the next battle',
    'superDefence.info': 'The defense of a random hero is increased by 5 times, but the attack is reduced by 2 times in the next battle',
    'mushroomMarker.info': 'The attack of all heroes is increased by 2 times, but the defense is reduced to 1 in the next battle',
    'mushroomMarker2.info': 'The Shaman\'s Mushroom grants +20 defense and -10 attack to all heroes in the next battle',
    'looseForcePhoenix.info': 'Your opponents lose their phoenix power in their next battle',
    'looseForceDeath.info': 'Your opponents lose the power of death in their next battle',
    'looseForceWitch.info': 'Your opponents lose their witch power in their next battle',
    'looseForceMag.info': 'Your opponents lose mage power in their next battle',
    'exp_x10.info': 'If you win the next battle, you will receive ten times the experience',
    'anyHeroAttackX3.info': 'The attack of your or an enemy random creature in the next battle will be tripled',
    '3_vict_sphere.info': 'Win the next three battles and get +100 attack for all heroes',
    'new_level_on_victory.info': 'If you win the next battle, you will immediately receive a new level',
    'pct.for.lev': '% FOR EACH LEVEL',
    'rep.for.lev': '% OF DEFENDED PUNCHS FOR EVERY LEVEL OF CONSTRUCTION',
    'factory.for.lev': '% GIFT VALUE PER LEVEL OF CONSTRUCTION',
    'edit.clan.txt': 'Edit the text as you like! Place here the rules of your clan, the appeal to players, and anything else you wish',
    'con.fail': 'SERVER CONNECTION HAS BEEN TERMINATED',
    'try.ltr': 'TRY TO CONNECT LATER',
    'connect': 'CONNECT',
    'nm.chng.cmnt': 'You are trying to change your characters name',
    'frc.nm.chng.cmnt': 'You need to change your character`s name',
    'nm.chng.costs': 'Changing the name will cost to you',
    'ml.or.phn.chng.cmnt': 'Not specified',
    'ml.or.phn.chng.crrnt': 'Will be needed to reset password. Current email or phone:',
    'phn.format.desc': 'The phone number must begin with a + sign and contain the country and area code',
    'passw.chng.fill': 'Fill in all fields to proceed',
    'passw.chng.header': 'PASSWORD CHANGE',
    'passw.chng.info': 'Here you can set up a new password. After changing the password, you will need to re-enter the game',
    'passw.chng.curr': 'Current password',
    'passw.chng.new': 'New password',
    'passw.chng.new.rpt': 'Repeat new password',
    'set.new.name': 'Make up a new nickname',
    'set.new.email.or.phn': 'Set up a new email or phone',
    'banned': 'You are not allowed to chat, possible reason: chat ban',
    'all.emotions': 'All emotions',
    'min.chat.lvl': 'You need level 10 to send a message!',
    'ent.text': 'Type a message...',
    'pl.total': 'Players total',
    'cl.total': 'Clans total',
    'online.total': 'Online total',
    'yan': 'YAN',
    'gls': 'gls',
    'OK': 'OK',
    'fm': 'FM',
    'kr': 'KR',
    'tg.star': 'ST.',
    'to.blck.list': 'TO BLACK LIST',
    'from.blck.list': 'UNBLOCK',
    'cust.slots': 'CUSTOMIZE SLOTS',
    'backpack': 'BACKPACK',
    'poniatno': 'Clear',
    'backpack.info': 'If the slots algorithm not specified, items for fight will be chosen randomly',
    'block.user': 'You are about to add the player to your black list. This action will arase all the messages between you and him (her), and will stop him (her) from later messaging you.',
    'unblock.user': 'Remove player from the black list?',
    'blocked.feed': 'Player added to the black list. He will no more disturb you.',
    'unblocked.feed': 'You removed the player from your black list',
    'slots.set': 'Slots set up',
    'scroll': 'SCROLL',
    'random': 'RANDOM',
    'type': 'TYPE: ',
    'effect': 'EFFECT: ',
    'power': 'POWER: ',
    'weakest': 'WEAKEST',
    'strongest': 'STRONGEST',
    'conf': 'Confirm',
    'cust': 'Settings',
    'consls': 'ELIXIRS AND SCROLLS',
    'cns.txt' : 'Customize battle elixirs and scrolls. Set up your own preferences. If present items for battle will be chosen according to your setup.',
    'continue' : 'Continue',
    'rej': 'REJECT',
    'invtd': 'Invited',
    'rjctd': 'Rejected',
    'need.accpt': 'In order to register you need to accept the agreement and agree to Cookie usage.',
    'priv.pol': 'Privacy Policy',
    'public.offer': 'Public Offer',
    'user.agreement': 'User Agreement',
    'i.acc': 'I accept',
    'acc.cook': 'I agree to cookie usage',
    'sublead': 'Temp. Leader',
    'zamlead': 'Deputy Leader',
    '2.exch': 'TO EXCHANGE',
    'sel.heroes': 'SELECT HEROES TO GIVE THEM ON ACCOUNT OF PURCHASE',
    'hero.dec.price': 'EVERY GIVEN HERO DECREASES PRICE OF THE PURCHASE',
    'her.sel': 'HEROES SELECTED:',
    'add.attack': 'additional attack',
    'h.shop': 'HERO SHOP',
    'h.usual': 'USUAL',
    'h.legend': 'LEGEND',
    'h.myth': 'MYTH',
    'her.unavailable': 'These heroes are unavailable yet.',
    'min.lev.legend': 'Min level for Legendary heroes: ',
    'min.lev.myth': 'Min level for Myth heroes: ',
    'myth.her': 'MYTH HEROES',
    'myth.ava': 'Myth heroes are now available!',
    'too.many.her': 'Too many unused heroes? Just get a myth hero in exchange for them!',
    'myth.are.cool': 'Myth heroes possess unique forces, unavailable for others. They are very expensive. Nevertheless you can pay for them with old unused heroes.',
    'warlock': 'WARLOCK',
    'druid': 'DRUID',
    'witch': 'WITCH',
    'shaman': 'SHAMAN',
    'dragonslayer': 'DRAGONSLAYER',
    'dragon_2': 'DRAGON RNK.3',
    'warlock.gen': 'WARLOCK',
    'druid.gen': 'DRUID',
    'witch.gen': 'WITCH',
    'shaman.gen': 'MONK',
    'dragonslayer.gen': 'DRAGONSLAYER',
    'dragon_2.gen': 'DRAGON RNK.3',
    'can.not.improve.rank': 'THIS CREATURE CAN NOT IMPROVE RANK.',
    'success.purch': 'SUCCESSFUL PURCHASE!',
    'gems.arrived': 'YOUR GEMS ARRIVED!',
    'h.pl': 'ALL YOUR HEROE\'S HEALTH +',
    'disc.myth': 'MYTH HEROES DISCOUNT ',
    'get': 'ACCEPT',
    'get.for.clan': 'GET FOR CLAN',
    'all.h': 'TO ALL HEROES',
    'disc.pl': '+DISCOUNT ',
    'ev.myth': '% TO EVERY MYTH./TOP HERO',
    'us.inf.1': 'Here you\'l find affordable, but unexperienced heroes.',
    'us.inf.2': 'Improve them and reinforce your heroes.',
    'leg.inf.1': 'Legenday heroes are experienced and strong.',
    'myth.inf.1': 'Myth heroes possess unique forces, available for nobody else.',
    'myth.inf.2': 'They are super expensive, but you can pay with old unused heroes.',
    'myth.in.clan': '*Myth heroes are available in a clan shop',
    'catto': 'Catto',
    'joanne': 'Joanne',
    'johnny': 'Alice',
    'news': 'NEWS',
    'manual': 'MANUAL',
    'c.chests': 'OFFERING: CHESTS!',
    'c.chests2': 'NEW CHESTS!',
    'c.chests3': 'SUPER CHESTS!',
    'c.wheel': 'WHEEL OF FORTUNE!',
    'c.hero': 'GET A FREE HERO!',
    'c.snow_world': 'SNOW WORLD FOR FREE!',
    'c.boosters': 'GET FREE BOOSTERS!',
    'c.portal_discount': 'PORTALS FOR FREE!',
    'c.gems_plus_50': '+50% GEMS FOR FREE!',
    'c.chest': 'Special offer: Get a free chest!',
    'c.wheel2': 'Special offer: Wheel of fortune!',
    'c.hero2': 'Special offer: Get a free hero!',
    'c.snow_world2': 'Offering: Get a free Snow world access!',
    'c.boosters2': 'Special offer: Get free boosters!',
    'c.portal_discount2': 'Offering: Up to 100% off portals access!',
    'c.gems_plus_50_2': 'Sale: Buy gems and get +50% for free!',
    'tickets.received': 'Tickets received',
    'hero.received': 'Hero received',
    'gems.received': 'Gems received',
    'attack.increased': 'Attack increased',
    'defence.increased': 'Defence increased',
    'health.increased': 'Health increased',
    'crystals.received': 'Crystals received',
    'artifact.received': 'Artifact received',
    'force.received': 'Force received',
    'm.chest': 'MY CHEST',
    'tickets.conds': 'Get a golden ticket for every {0} gems bought in the shop!',
    'tickets.sources': 'Spin the wheel and win valuable prizes!',
    'wheel.det': 'Reward chances',
    'spin.for' : 'Spin for ',
    'spin.free' : 'Spin\nfor free',
    'take.award' : 'Take award',
    'you.have' : 'You have ',
    'you.have.free.spins' : 'Free spins: ',
    'silv.conds': 'Buy any quantity of gems and get a silver chest!',
    'silv.det': 'What is in a silver chest?',
    'gold.conds': 'Buy 10 000 gems (or more) and get a golden chest.',
    'free.hero.conds': 'Buy {0} gems or more and get a free character.',
    'free.snow.world.conds': 'Buy {0} gems or more and get a free access to Snow world for {1} hours.',
    'portal.discount.cond': 'Buy at least {0} gems and get a 50% discount for any portal.',
    'portal.discount.cond2': 'Buy {0} gems or more and get a 100% discount for any portal.',
    'booster.cond': 'Buy any amount of emeralds and get +100% experience for 8 hours as a gift.',
    'booster.cond2': 'Buy at least {0} emeralds and get Platinum set for 72 hours as a gift (battle experience +400%, health per level +400%, regeneration speed x20)',
    'gold.det': 'What is in a golden chest?',
    'camp.no.more': 'You can use offer not more than',
    'camp.wheel.limit': 'The number of tickets per day is limited (Tickets received: {0}/{1})',
    'tms.used': ' times per day (used: ',
    'tm.used': ' time per day (used: ',
    'cmp.dates': 'Offer dates: ',
    'slv.chst': 'SILVER CHEST',
    'gld.chst': 'GOLDEN CHEST',
    'az.chst': 'AZURE CHEST',
    'lv.chst': 'LAVA CHEST',
    'amb.chst': 'AMBER CHEST',
    'jd.chst': 'JADE CHEST',
    'open': 'OPEN',
    'all.her': 'to all heroes',
    'one.her': 'to one hero',
    'chst': 'CHEST',
    'hlth.plus': 'HEALTH +',
    'pick.who': 'Pick hero who will get ',
    'ft.news': 'News',
    'ft.sugg': 'Suggestions',
    'ft.disc': 'Game discussion',
    'ft.talk': 'Talk',
    'ft.play': 'Plays',
    'ft.help': 'Help',
    'ft.letopis': 'Chronicles',
    'ft.quest': 'Questions',
    'ft.meet': 'Connections',
    'ft.comp': 'Competitions',
    'ft.rules': 'Rules',
    'ft.cl.info': 'Info',
    'ft.cl.stuff': 'Clan stuff',
    'ft.cl.talk': 'Talk',
    'ft.cl.oth': 'Other',
    'frm': 'FORUM',
    'dlt': 'DELETION',
    'dlt.conf': 'Topic will be deleted. Are you sure?',
    'tpc.crt': 'CREATE TOPIC',
    'tpc.crtn': 'TOPIC CREATION',
    'tpc.edt': 'EDIT TOPIC',
    'cmnts': 'COMMENTS',
    'com.edt': 'EDIT COMMENT',
    'com.dlt.conf': 'Comment will be deleted. Are you sure?',
    'write': 'WRITE',
    'com.crtn': 'COMMENT CREATION',
    'discn': 'DISCUSSION',
    'srch.sett': 'SEARCH SETTINGS',
    'srch': 'SEARCH',
    'arena.sett': 'ARENA SETTINGS',
    'choose.arena.time': 'Choose local time for arena reward',
    'by.rate': 'BY RATING',
    'by.new': 'BY DATE',
    'sort': 'SORTING',
    'tags': 'TAGS',
    'filts': 'FILTERS',
    'by.all.time': 'ALL TIME',
    'by.week': 'LAST WEEK',
    'by.day': 'LAST DAY',
    'crtd': 'Created: ',
    'edtd': ', edited ',
    'delete': 'DELETE',
    'auth': 'Author: ',
    'guest': 'Guest',
    'to.top': 'Go to the topic >',
    'read.all': 'Continue reading >',
    'cln.frm.pr': '*Role of participant at least is required for topic creation',
    'comm.frm.pr': '*In order to post topics it is required to have 10th level at least and to be not blocked.',
    'private': 'Private',
    'top.name': 'Topic name',
    'top.cont': 'Enter text',
    'theme': 'Topic Tag',
    'gst.lbl': 'Private (for clan members) or guest (for all)',
    'opr': 'INTERVIEW',
    'dr.pl': 'Dear player!',
    'shr.opin': 'Share your opinion:',
    'd.u.like': 'Do you like the game?',
    'rt.on.yan': 'Rate us on Yandex.',
    'rt.on.google': 'Rate us on Google.',
    'hlp.evlv': 'This will help us to improve.',
    'rate': 'RATE',
    'like': 'LIKE',
    'n.like': 'DISLIKE',
    'm.ty.op': 'Thank you very much for your opinion!',
    'ty.for.op': 'Thank you for your opinion',
    'we.do.best': 'We will try to do our best! :)',
    'boosters': 'BOOSTERS',
    'now': 'NOW: ',
    'ttl': 'Title',
    'no.boo': 'BOOSTER IS NOT AVAILABLE',
    'repl.boo': 'Do you want to replace your current booster with this booster?',
    'boo.act': 'Activating booster ',
    'prol.boo': 'Prolonging your booster for ',
    'act.till': 'ACTIVE TILL ',
    'repl': 'REPLACE',
    'append': 'PROLONG',
    'ui.ch.ttl': 'Game UI',
    'ui.nw': 'Switch to new game UI (game restart is required)?',
    'ui.old': 'Switch to original game UI (game restart is required)?',
    'ui': 'UI',
    'gfts': 'GIFTS',
    'gft': 'GIFT',
    'gft.by.fotostrana': 'GIFT FROM PHOTO COUNTRY!',
    'gft.by.vk': 'GIFT FROM VK Play!',
    'gft.by.text': 'Accept 1000 emeralds as a gift. With love from VK Play.',
    'gft.fotostrana.text': 'Accept 50 emeralds as a gift. With love from Fotostrana.',
    'with.us': 'Thank you for being with us!',
    'gft.rcvd': 'Hurray! Gift accepted!',
    'gft.all.rcvd': 'Hurray! All gifts accepted!',
    'gft.new': 'New gift!',
    'u.have': 'You have',
    'n.g': 'new gifts!',
    'ne.g': 'You have a new gift!',
    'from2': 'from: ',
    'acpt.all': 'ACCEPT ALL',
    'nxt': 'NEXT',
    'next.rang': 'next rank',
    'force.has.max.rang': 'The force \n has the highest rank',
    'acpt': 'ACCEPT',
    'gft.pck': 'PICK A GIFT',
    'desire': 'COPLIMENT',
    'ent.desire': 'WRITE A COMPLIMENT TO YOUR GIFT',
    'ent.desire.2': 'Write a compliment for the player',
    'pres': 'PRESENT',
    'n.txt': '- no text -',
    'g.d.conf': 'Are you sure you want to delete the gift?',
    'fr': 'FRUITS',
    'food': 'FOOD',
    'ny': 'N.Y.',
    'ans': 'ANIMALS',
    'mk.gft': 'MAKE A GIFT',
    'gift.gems': 'GIFT GEMS',
    'gft.snt': 'You sent a gift to a player! He/she will be pleased.',
    'titan': 'TITAN',
    'titan.gen': 'TITAN',
    'show.txt': 'Show text',
    'hide.txt': 'Hide text',
    'txt.hid': 'Text hidden',
    't.h.all': 'Text is now hidden from everybody',
    't.s.all': 'Text is now shown to everybody',
    'br.camp': 'Unique creature with a discount!',
    'v.o.ttl': 'VERSION',
    'v.o.int': 'VERSION OBSOLETE',
    'v.o.body': 'This version has become obsolete. It is reccomended to reload the page or press "RELOAD" button',
    'cln.top': 'CLAN TOPIC',
    'pick.thm': 'CHOOSE A THEME FOR YOUR CLAN',
    'tpcs': 'THEMES',
    'thm.cmnt': 'FIRST 3 THEME CHANGES ARE FOR FREE. NEXT - 250 GEMS FROM CLAN BUDGET.',
    'silv.gn': 'SILVER',
    'gld.gn': 'GOLD',
    'brl.gn': 'BRILLIANT',
    'silv.nm': 'SILVER',
    'gld.nm': 'GOLD',
    'brl.nm': 'BRILLIANT',
    'bon.gms': ' BONUS GEMS!',
    'by.mr': 'Buy',
    'gms.silv': 'gems and get silver status.',
    'det': 'DETAILS',
    'auto.rotation': 'auto-rotation',
    'cngrts.stat': 'CONGRATULATIONS! YOU HAVE GOT',
    'b.sts': 'STATUS!',
    'st.desc.1': 'From now you will get bonus points for every purchase: ',
    'st.desc.2': '(you get bonus points: ',
    'pct.gms': '% of bought gems.',
    'pnts.got': 'YOU GOT',
    'pnts': 'BONUS POINTS',
    'pnts.nom': 'BONUS POINTS',
    'mk.purch.dr.week': 'Make a purchase during the week and get additional gem for every bonus point.',
    'bn.lim': '* But can not get bonus gems more than bought count',
    'u.hv': 'YOU HAVE ',
    'stts': ' STATUS',
    'mx.st': 'You have the highest status',
    'buy.get': ' gems in total (not counting gifted ones) and get ',
    'before.obs': 'before expire:',
    'ag.buy': 'Buy ',
    'ag.buy.mr': 'Buy ',
    'rem': 'REMAIN',
    'bf.obs': ' BEFORE BONUS POINTS EXPIRE!',
    'u.hv.bns': ' You have bonus points:',
    'hv.bns': '(bonus points:',
    'bt.agit': 'Make a purchase, in order to get gems for them.',
    'pct.fr': '% from bought gems amount)',
    'or.get': 'AND GET',
    'pl.btl': '+1 BATTLE FOR',
    'do': 'UP TO',
    'to.the': 'TO THE',
    'adv': 'ANNOUNCEMENT',
    'clan.name': 'Clan name',
    'enemy.attack': 'Enemy attack',
    'enemy.defence': 'Enemy defence',
    'enemy.attack.defence': 'Enemy attack and defence',
    'enemy.health': 'Enemy health',
    'player.attack': 'Hero attack',
    'player.defence': 'Hero defence',
    'player.attack.defence': 'Hero attack and defence',
    'player.health': 'Hero health',
    'adv.desc': 'Write an announcement for your clan members (180 symbols max)',
    'adv.placeholder': 'Announcement text',
    'publish': 'Publish',
    'w.adv': 'WATCH ADV',
    'w.rol': 'FOR ADV WATCH',
    '4gems': 'FOR GEMS',
    't.p.d': 'TIMES PER DAY',
    'w.a.g': 'WATCH ADV ANG GET',
    'ws.tdy': 'WATCHES TODAY',
    'wtch': 'WATCH',
    'gn.gems': 'GAIN GEMS',
    '2.mr': 'UP TO',
    'rew.lim': 'UP TO 3 TIMES PER DAY',
    'aw': 'REWARD',
    'gt': 'GET',
    'rew.res': 'Thank you for Adv watch! Watches today: ',
    'val': 'LOVE',
    'ifrit': 'IFRIT',
    'ifrit.gen': 'IFRIT',
    'life': 'LIFE',
    'life.gen': 'LIFE',
    'death': 'DEATH',
    'death.gen': 'DEATH',
    'chaos': 'CHAOS',
    'chaos.gen': 'CHAOS',
    'wall': 'STRENGTH',
    'wall.gen': 'STRENGTH',
    'lord': 'LORD',
    'lord.gen': 'LORD',
    'vamp': 'VAMPIRE',
    'vamp.gen': 'VAMPIRE',
    'luck': 'FORTUNE',
    'luck.gen': 'FORTUNE',
    'bers': 'BERSERK',
    'bers.gen': 'BERSERK',
    'wind': 'WIND',
    'wind.gen': 'WIND',
    'sorc': 'SORCERER',
    'sorc.gen': 'SORCERER',
    'east': 'EASTER',
    'sst': ' IT',
    'plc': 'PLACE',
    'km.snow': 'SNOW WORLD DISTANCE',
    'met': 'meters',
    'b.snow': 'Increases max. distance in Snow World',
    'm.p.lev': 'meters per level',
    'p.lev': 'per level',
    'copy': 'Copy link',
    'pin.topic': 'Pin topic',
    'unpin.topic': 'Unpin topic',
    'copied': 'Link copied',
    'card.used': 'Card used!',
    'spr': 'MANUAL',
    'spr.forces': 'FORCES MANUAL',
    'sprng': 'SPRING',
    'june-3': 'News! Starting from June 3, there will be new hero exchange rules.',
    'pck.ava': 'Would you like to have a new avatar?',
    'lvlng': 'LEVELING',
    'lvlg.comb': 'Group leveling, applying "to all heroes" applies to 6 combative heroes. But you can set up to upgrade the weakest creatures. Do you want to set up this mode?',
    'lvlg.weak': 'Group leveling, applying "to all heroes" applies to 6 weakest heroes. But you can set up to upgrade combative creatures. Do you want to set up this mode?',
    'lvlg.comb.st': 'All set. Now upgrades will affect the weakest creatures.',
    'lvlg.weak.st': 'All set. Now upgrades will affect combative/the strongest creatures.',
    'angel': 'ANGEL',
    'archangel': 'ARCHANGEL',
    'archititan': 'ARCHITITAN',
    'yotun': 'YOTUN',
    'asg.phonix': 'ASG. PHOENIX',
    'asg.phonix.full': 'ASGARDIAN PHOENIX',
    'angel.gn': 'ANGEL',
    'archangel.gn': 'ARCHANGEL',
    'archititan.gn': 'ARCHITITAN',
    'yotun.gn': 'YOTUN',
    'asg.phonix.gn': 'ASG. PHOENIX',
    'min.lev.top': 'To get access to mythical characters, you need to defeat the enemy min. ',
    'top.her.t': 'SUPREME HEROES',
    'top.her.n': 'Supreme heroes are available to you!',
    'top.her.1': 'Supreme heroes are the strongest heroes in the game.',
    'top.her.2': 'Typically, supreme heroes have gifts up to level 5',
    'h.top': 'SUPREME',
    'top.inf.1': 'Exchange is not available for supreme heroes. However, they are still subject to the same discounts as mythic heroes.',
    'quality': 'QUALITY',
    'qi': 'Image quality',
    'qi.set': 'Setup image quality. It is reccommended to set high quality on modern devices. But in slow devices low quality is preferred to gain better performance',
    'nd.re': '(Need restart)',
    'q.lst': 'Very low',
    'q.lw': 'Low',
    'q.st': 'Standard',
    'q.hg': 'High',
    'knl.d': 'School Day!',
    'it.levs': 'ITEM LEVELS:',
    'impr': 'Update: ',
    'spdp': 'Speedup: ',
    'art.d': 'ITEMS DISCOUNT!',
    'art.d.d': 'DISCOUNTS ON ALL ITEMS IN THE SHOP.',
    'watch': 'TAKE A LOOK',
    't.c.e': 'CAMPAIGN DATES: ',
    'clan.invite': 'invited to the clan',
    'clan.joined': 'joined the clan',
    'clan.reject': 'rejected the clan invitation',
    'clan.kick': 'kicked out of the clan',
    'clan.left': 'left the clan',
    'clan.raise': 'promoted to a new position',
    'clan.subsidence': 'demoted',
    'clan.goldDonated': 'donated gold to the clan',
    'clan.redCrystalDonated': 'donated red crystals to the clan',
    'clan.blueCrystalDonated': 'donated blue crystals to the clan',
    'clan.gemsDonated': 'donated gems to the clan',
    'clan.gearsDonated': 'collected gears for the clan',
    'clan.leaderReplace': 'passed on the leadership to',
    'clan.created': 'initiated the building upgrade',
    'clan.subLeader': 'appointed a deputy in the clan',
    'clan.upgradeWallOfFame': 'upgraded the Clan Wall of Fame',
    'clan.changeDescription': 'changed the clan`s description',
    'clan.changeTheme': 'changed the clan`s theme',
    'clan.giveAdvertisement': 'placed an advertisement in the clan',
    'clan.changeDifficulty': 'changed the clan task difficulty',
    'one.free': 'Once a day free!',
    'hlw': 'Halloween',
    'wall.r.7': 'WALL RNK. 7',
    'wall.r.7.gn': 'WALL RNK. 7',
    'death.r.7': 'DEATH RNK. 7',
    'death.r.7.gn': 'DEATH RNK. 7',
    'witch.5.1': '- X6 VS GOLEMS',
    'witch.5.2': '- IGNORES GOLEM SHIELD',
    'witch.5.3': '- FORCES ENEMY TO ATTACK HIS ALLY TWICE',
    'witch.7.1': '- X6 VS GOLEMS, IGNORES GOLEM SHIELD',
    'witch.7.2': '- FORCES ENEMY TO ATTACK HIS ALLY TWICE',
    'witch.7.3': '- FORCES ENEMY TO GROUP ATTACK OF ALLY',
    'skip.mv': 'SKIP MOVE',
    'sk.mv': 'MOVE SKIPPED',
    'mag.r.2': 'MAGE RNK.2',
    'mag.r.2.gn': 'MAGE RNK.2',
    'chaos.r.7': 'CHAOS RNK. 7',
    'chaos.r.7.gn': 'CHAOS RNK. 7',
    'chaos.7.1': 'IGNORES 99.9999% ENEMY DEFENCE',
    'pl.on': 'For player on',
    'th.pl': ' place',
    'rl': 'Road Length: ',
    'km': 'km.',
    'h.d': ' - huge discount!',
    't.p': 'TO PORTAL',
    'pt': 'PORTAL',
    'discount': 'DISCOUNT',
    '8mr': 'March 8',
    'brth': 'Game BRTHD',
    'cat.rain': 'Oh my God! What is it!?',
    'hum.rain': 'It is just a rain, friends. It happens.',
    'ntr': 'New task: To the White Rabbit!',
    'help': 'HELP',
    'h.h': 'I hear a call for help. Someone\'s in trouble! Let\'s go!',
    'thirty.emeralds': 'with 30 emeralds',
    'hundred.emeralds': 'with a 100 emeralds',
    'five.hundred.emeralds': 'with 500 emeralds',
    'thousand.emeralds': '1000 emeralds',
    'gft.by.code': 'GIFT BY CODE!',
    'gft.by.code.text': 'By using promo code VIP you get a gift chest with ',
    'gft.by.code.text2': ' emeralds!',
    'with.love': 'With love, your Mirrorland!!!',
    'm': 'm.',
    'pc.': 'pc.',
    'card.elephant': 'Battle elephant card',
    'card.snake': 'Snake card',
    'card.elephant.tutorial': 'Now just select a hero and a gift as usual. And enjoy the zero cost for the instant gift upgrade.',
    'you.activated.card': 'You have activated the card!',
    'available': 'Available',
    'card.is.active': 'Card is active',
    'choose.hero.and.improve.force': 'Choose hero and improve force',
    'ban.reason': 'Select the reason for the ban',
    'ban.reason.flood': 'Flood',
    'ban.reason.profanity': 'Foul language',
    'ban.reason.insults': 'Insults',
    'ban.reason.provocative.action': 'Provocation',
    'ban.reason.trade': 'Trading',
    'ban.reason.advertisement': 'Advertising',
    'ban.reason.fraud': 'Fraud',
    'ban.reason.nickname': 'Forbidden nickname',
    'ban.reason.banned.topics': 'Forbidden topics',
    'ban.reason.laws': 'Laws of Russia',
    'hero.for.the.quest': 'COMPLETE THE MISSION AND GET A MYTHICAL CHARACTER. ',
    'cost': 'Cost in',
    'emeralds': ' EMERALDS.',
    'emeralds.small': 'emeralds ',
    'sulphur': ' sulphur.',
    'share': 'SHARE (+15 emeralds)',
    'share2': 'SHARE',
    'text.is.too.long': 'The text is too long',
    'level': ' (level ',
    'purchase': 'Purchase ',
    'emeralds.for': ' emeralds for ',
    'gift.emeralds.for': ' gift emeralds for ',
    'payment.id': 'Payment Id: ',
    'receiver.id': 'Receiver id: ',
    'payment.srated': 'PAYMENT STARTED',
    'emeralds.accrued.but.not.received': 'EMERALDS ACCRUED BUT NOT RECEIVED',
    'return': 'RETURN',
    'gain.part.of.friend.emeralds': 'Invite your friends and get up to 30% of the emeralds they buy.',
    'send.ref.link.to.friend': 'SHARE THE REFERRAL LINK WITH FRIENDS',
    'filter.by.category': 'Filter by category:',
    'filter.by.subcategory': 'Filter by subcategory:',
    'filter': 'Filter',
    'social.invite.text': 'Enjoy Mirrorland game with me!',
    'social.invite.bonus': 'Get a bonus 99 emeralds at the start of the game',
    'again.in': 'AGAIN IN ',
    '1h': '1 hour',
    '3h': '3 hours',
    '6h': '6 hours',
    '12h': '12 hours',
    '1d': '1 day',
    '3d': '3 days',
    '1w': 'one week',
    '2w': 'two weeks',
    'copied.large': 'COPIED',
    'copied.id': 'PASTE ID COPIED',
    'source': 'SOURCE:',
    'unban': 'UNBAN',
    'unban.question': 'Are you sure you want to unban this player?',
    'log.in.as.a.player': 'LOG IN AS A PLAYER',
    'history': 'HISTORY',
    'payments': 'PAYMENTS',
    'moderator.upgrade': 'UPGRADE TO MODERATOR',
    'moderator.upgrade.success': 'Player promoted to moderator',
    'moderator.downgrade': 'DOWNGRADE TO PLAYER',
    'moderator.downgrade.success': 'The moderator downgraded to the player',
    'more': 'MORE',
    'openly.improve': 'IMPROVEMENT IS OPEN TO ',
    'level.up': ' LEVEL!',
    'mythical.hero': 'MYTHICAL HERO',
    'heroes.island': 'ISLAND OF HEROES',
    'not.enough.amethysts': 'Not enough amethysts',
    'amethysts.you.have': 'You have amethysts: ',
    'angels.city': 'CITY OF ANGELS',
    'accept.angel': 'Take the Angel in your ranks.',
    'appmetrica.active': 'AppMetrica active',
    'appmetrica.success': 'AppMetrica successfully activated',
    'appmetrica.error': 'AppMetrica error',
    'get.gift': 'GET THE GIFT!',
    'join.group': 'JOIN THE GROUP',
    'join.group.official': 'JOIN THE OFFICIAL ODNOKLASSNIKI GROUP',
    'donate': 'WE\'RE GIVING ',
    'donate.all': ' EMERALDS TO ALL THE GROUP MEMBERS!',
    'you.are.in.group': ' You\'re in the group. Accept the emeralds!',
    'join.group.donate': 'Join the group to get emeralds.',
    'you.got.amethysts': 'Emeralds received!',
    'accept.donate': 'Accept',
    'in.group': 'To the group',
    'oops': 'Oops',
    'you.used.action': 'You\'ve already used the offering.',
    'heroes.gate': 'HEROES\' CASTLE',
    'buy.unique.hero.from.castle': 'Buy a unique character from the castle of heroes.',
    'bath.broom': 'Bath broom, fresh towel and slippers are provided',
    'player.unbanned': 'Player unbanned',
    'accept.15.donate': 'Accept 15 emeralds!',
    'game.credits': 'Currency of Mirrorland',
    'market.s': 'SUR\'S MARKET',
    'accept.hero.from.market.s': 'Take a character from the Sur\'s Market into your ranks.',
    'titan.gate': 'CASTLE OF TITANS',
    'buy.unique.hero.from.castle.titan': 'Buy a unique hero from castle of titans.',
    'city.yotunov': 'CITY OF JOTUNS',
    'accept.hero.from.city.yotunov': 'Take the Jotun into your ranks.',
    'intro': 'Mirrorland: adventures, battles, cats. Join the game!',
    'game.name': 'Mirrorland Game',
    'htr': 'Hit the road',
    'mults': 'MULTS',
    'koschey': 'IMMORTAL',
    'koschey.gn': 'IMMORTAL',
    'cyclop': 'CYCLOP',
    'cyclop.gn': 'CYCLOP',
    'poison': 'NAGA',
    'poison.gn': 'NAGA',
    'keeper': 'KEEPER',
    'keeper.gn': 'KEEPER',
    'sphinx': 'SPHINX',
    'sphinx.gn': 'SPHINX',
    'norn': 'NORN',
    'norn.gn': 'NORN',
    'provocator': 'PROVOCATOR',
    'provocator.gn': 'PROVOCATOR',
    'shadow': 'SHADOW',
    'shadow.gn': 'SHADOW',
    'priestess': 'PRIESTESS',
    'priestess.gn': 'PRIESTESS',
    'as': 'AS',
    'as.gn': 'AS',
    'young': 'YOUNG',
    'epic': 'EPIC',
    'shad.tn': 'SHADOW TOWN',
    'get.p': 'SUCCESSFUL PURCHASE',
    'h.w.u': 'HERO WITH US NOW!',
    'also.bon': 'AND ALSO BONUS GEMS!',
    'also.bon.2': 'AND ALSO FOR BONUS PROGRAM!',
    's.h': 'SACK OF HEALTH',
    'p.all': '% TO EACH HERO',
    'tick': 'TICKETS FOR THE WHEEL!',
    'all.hero.7': 'All heroes forces can be improved up to 7 rank',
    'ns.f': 'Need Nuk-Serra power to summon heroes',
    'hv': 'HAVE: ',
    'disc.mth': ' ON MYTH HEROES',
    'free.gms': 'FREE GEMS. JUST TAKE!',
    'an.y.m': 'Another.. You',
    'an.y.w': 'Another.. You',
    'cont': 'CONTINUATION!',
    'and.nw': 'AND NOW...',
    'wlcm': 'WELCOME!',
    'wn': 'WHAT\'S NEXT?',
    't.a': 'TO ADVENTURES!',
    'port.shp': 'Portal Shop',
    'sht.sur': 'Sur',
    'sht.port': 'Portal',
    'sm.cln': 'Same clan',
    'kln': 'Clan: ',
    'by.ap': 'By apples',
    'pr.free': 'Free portal use',
    'az.conds': 'Buy 3000 gems or more and get Azure Chest!',
    'az.det': 'What can be inside Azure Chest?',
    'lv.conds': 'Buy 50 000 gems or more and get Lava Chest!',
    'lv.det': 'What can be inside Lava Chest?',
    'by.bk': 'By books',
    'by.pk': 'By pumpkin',
    'p1.1': '*** You have completed part I of the game! ***',
    'p1.2': 'It was an amazing, fabulous adventure.',
    'p1.3': 'And it continues!',
    'p1.4': 'You are already going through the portal to the Legendary Asgard.',
    'p1.5': 'In the meantime, please accept our most ardent congratulations!',
    'p1.6': 'And gratitude for being with us in the most epic game - Mirrorland!',
    'p1.7': 'Please accept our warmest wishes, and good luck on your journey.',
    'p1.8': 'In the meantime, you can already see the light...',
    'p1.9': 'Asgard awaits you.',
    'p1.10': 'PART II.',
    'lgo': 'LET\'S GO!',
    'p2.1': '*** You have completed the 2nd part of the game! ***',
    'p2.2': 'You have become as strong as a storm and as fast as the wind.',
    'p2.3': 'You have saved not only the Looking Glass, but also Asgard and the Emerald City.',
    'p2.4': 'You are returning to your world, and you will never be the same again.',
    'p2.5': 'And your journey continues!',
    'p2.6': 'The Looking Glass still has something to surprise you with! Oh, how it is!',
    'p2.7': 'Meanwhile, you have arrived in your world.',
    'p2.8': 'Forward, towards a new adventure!',
    'p2.9': 'PART III. Lord Ramses.',
    'p3.1': '*** You have completed the 3rd part of the game! ***',
    'p3.2': 'Accept our warm congratulations, dear friend!',
    'p3.3': 'You have completed three parts, and now you are truly one of us.',
    'p3.4': 'One of the chosen ones of the Looking Glass! Part of the Looking Glass!',
    'p3.5': 'We are very glad that you are with us. May the amazing epic mood never leave you!',
    'p3.6': 'In the meantime, a new challenge awaits you...',
    'p3.7': 'PART IV. BLACKBOOKS',
    'p4.1': '*** You have completed the 4th part of the game! ***',
    'p4.2': 'You have defeated incredibly strong opponents, and once again proved that you are the best!',
    'p4.3': 'Please accept our deepest gratitude!',
    'p4.4': 'Thank you for being with us! For being one of us!',
    'p4.5': 'And one of the most interesting and epic parts of the game awaits you!',
    'p4.6': 'And now, on your way! The Elder has something to tell you!',
    'p4.7': 'PART V. THE WITCH',
    'amb.conds': 'Buy 7 000 gems or more and get an Amber chest!',
    'amb.det': 'What can be in an Amber chest?',
    'jd.conds': 'Buy 20 000 gems or more and get a Jade chest!',
    'jd.det': 'What can be in a Jade chest?',
    'art.slot': 'ARTIFACT SLOT',
    'amtst': ' ametists',
    'mboo': 'Platinum set',
    'p8.1': 'Skuld must have defeated Dark Serra.',
    'p8.2': 'But Skuld was too late.',
    'p8.3': 'Dark Serra bought time and saved you',
    'p8.4': 'An unknown force carries you through a long portal tunnel.',
    'p8.5': 'Now you see the light...',
    'p8.6': 'Welcome to Mirograd.',
    'p8.7': 'You fly straight into the crater. The flight takes your breath away.',
    'p8.8': 'It becomes unbearably hot. Your skin burns...',
    'p8.9': 'You plunge into the molten lava...',
    'p8.10': 'Your thoughts leave you...',
    'p8.11': 'Welcome...',
    'p8.12': '... Into the Abyss.',
    'p8.13': 'You used the key',
    'p8.14': 'At the last moment, you saw Skuld\'s body move.',
    'p8.15': 'She began to revive.',
    'p8.16': 'But it was too late for her.',
    'p8.17': 'Alas, Skuld will remain in the Abyss forever.',
    'p8.18': 'You, however, are already rushing to your home world.',
    'p8.19': 'You did it.',
    'fireRobeMarker.info': 'Fire Robe enhances you in your next battle',
    'waterRobeMarker.info': 'Water Robe enhances you in your next battle',
    'mar': 'Faina',
    'marko': 'Liam',
    'turn': 'TURN',
    'wfr': 'WAY IS CLEAR NOW',
    'cmb': 'for battle heroes',
    'cht': 'CHEST TYPES:',
    'chd': '✨ Click on a chest to open details ✨',
    'p.q': 'Obtains packs one by one',
    'pr.pck': 'Please take previos pack before',
    'compl': 'done',
    'got': 'taken',
    'b.fr': 'Black Friday',
    's.n.s': 'Sale is not started yet',
    'qty': 'quantity: ',
    'rst': 'remain: ',
    'sold': 'sold out (',
    'lst': 'the last! (',
    'bf.welc': '✨ WELCOME TO THE BLACK FRIDAY! ✨',
    'bf.st': 'Sale will begin at 12:00',
    'bf.ts': 'Till start:',
    'bf.welc.2': '✨ PACKS SALE! ✨',
    's.c': 'Shadow Castle',
    'have.cav': 'Your caviar: ',
    'offs': 'OFFERS',
    'r.got': 'GOT THE RANK! PERFECT!',
    's.e': 'SNOW ELEPHANT',
    'cav': 'CAVIAR',
    'cav.g': ' OF CAVIAR',
    'n.e.c': ' Not enough caviar!',
    'discount.20percent.mythical.heroes': '20% OFF ON MYTHICAL HEROES',
    'to.purchase': 'To purchase',
    'to.player.him': 'him',
    'to.player.her': 'her',
    'for.player.him': 'him',
    'for.player.her': 'her',
    'special.gift': 'Special gift from {0}!',
    'buying.gems.for': 'Buying gems for',
    'make.a.special.gift': 'Make a special gift for a player!',
    'gift.from': 'Gift from {0}',
    'pss.1': 'Thank you for your purchase!',
    'pss.2': 'The gift has been sent and will be received by your recipient very soon. We wish you a pleasant game and a great mood!',
    'g.p.w': '* Chest and Wheel campaigns do not apply to gifts for another player.',
    'something.special': 'Something special!',
    'p3': 'ATLANTIS FREE',
    'p5': 'TROPICS FREE',
    'p6': 'WATER WORLD FREE',
    'p3.i': 'ATLANTIS',
    'p5.i': 'TROPICS',
    'p6.i': 'WATER WORLD',
    'p3.d': 'Free enter into portal Atlantis!',
    'p5.d': 'Free enter into portal Tropics!',
    'p6.d': 'Free enter into portal Water World!',
    'card.snake.tutorial': 'Choose a hero and a gift that will receive the 80% discount and acceleration',
    'card.eagle.tutorial': 'Select a hero for rank upgrade',
    'card.evolution.universal': 'Evolution',
    'card.evolution.ace': 'Evolution to As',
    'card.evolution.luck': 'Evolution to Luck',
    'card.evolution.chaos': 'Evolution to Chaos',
    'card.evolution.death': 'Evolution to Death',
    'card.evolution.druid': 'Evolution to Druid',
    'card.evolution.dryad': 'Evolution to Dryad',
    'card.evolution.faceless': 'Evolution to Faceless',
    'card.evolution.gorgon': 'Evolution to Gorgon',
    'card.evolution.highlander': 'Evolution to Highlander',
    'card.evolution.inaccessibility': 'Evolution to Inaccessibility',
    'card.evolution.incubus': 'Evolution to Incubus',
    'card.evolution.koschei': 'Evolution to Koschey',
    'card.evolution.manticore': 'Evolution to Manticore',
    'card.evolution.nun': 'Evolution to Nun',
    'card.evolution.phoenix': 'Evolution to Phoenix',
    'card.evolution.rider': 'Evolution to Rider',
    'card.evolution.shadow': 'Evolution to Shadow',
    'card.evolution.sphinx': 'Evolution to Sphinx',
    'card.evolution.succubus': 'Evolution to Succubus',
    'card.evolution.witch': 'Evolution to Witch',
    'detail':'Detail',
    'evolution':'Evolution',
    "evolution_text": "Evolution of the gift [currentForce] into the gift [targetForce]",
    "evolution.gifts": "Replacement of the [selectedClass] gift for the hero [hero name]\nChoose a gift to replace",
    "evolution_description": "The Epic Evolution Card allows you to replace any gift with any of the following:",
    "incompatible.force":"Incompatible force: ",
    "incompatible.force.phoenix": "asgardian phoenix + phoenix",
    "incompatible.force.shadow": "shadow + light shadow",
    "incompatible.force.inkub": "incubus + succubus",
    "incompatible.force.succub": "succub",
    "card.evolution.universal.tutorial": 'Select a hero and a force, then replace it with a new one',
    "card.evolution.ace.tutorial": "Select a hero and a force, then transform it into the Ace force",
    "card.evolution.luck.tutorial": "Select a hero and a force, then transform it into the Luck force",
    "card.evolution.chaos.tutorial": "Select a hero and a force, then transform it into the Chaos force",
    "card.evolution.death.tutorial": "Select a hero and a force, then transform it into the Death force",
    "card.evolution.dryad.tutorial": "Select a hero and a force, then transform it into the Dryad force",
    "card.evolution.faceless.tutorial": "Select a hero and a force, then transform it into the Faceless force",
    "card.evolution.gorgon.tutorial": "Select a hero and a force, then transform it into the Gorgon force",
    "card.evolution.human.tutorial": "Select a hero and a force, then transform it into the Human force",
    "card.evolution.inkub.tutorial": "Select a hero and a force, then transform it into the Incubus force",
    "card.evolution.koschey.tutorial": "Select a hero and a force, then transform it into the Koschei force",
    "card.evolution.manticore.tutorial": "Select a hero and a force, then transform it into the Manticore force",
    "card.evolution.nun.tutorial": "Select a hero and a force, then transform it into the Nun force",
    "card.evolution.phoenix.tutorial": "Select a hero and a force, then transform it into the Phoenix force",
    "card.evolution.rider.tutorial": "Select a hero and a force, then transform it into the Rider force",
    "card.evolution.shadow.tutorial": "Select a hero and a force, then transform it into the Shadow force",
    "card.evolution.sphinx.tutorial": "Select a hero and a force, then transform it into the Sphinx force",
    "card.evolution.sukkub.tutorial": "Select a hero and a force, then transform it into the Sukkub force",
    "card.evolution.witch.tutorial": "Select a hero and a force, then transform it into the Witch force",
    "card.evolution.druid.tutorial": "Select a hero and a force, then transform it into the Druid force",
    'active':'Acticate!',
    'card.eagle': 'Eagle card',
    'cancel.card.action': 'Cancel card action',
    'p2': 'EDEN FREE',
    'p2.i': 'EDEN',
    'p2.d': 'Free enter into portal Eden!',
    'amulet.shop': 'AMULET SHOP',
    'amulets': 'AMULETS',
    'am.comm': 'Rare amulets with unique bonuses',
    'eff.att': 'ATTACK +{0}',
    'eff.def': 'DEFENSE +{0}',
    'eff.a.d': 'ATTACK AND DEFENSE +{0}',
    'eff.hl': 'HEALTH +{0}',
    'eff.k.att': 'Harm to kosch. +{0}',
    'eff.k.def': 'Kosch. defense. +{0}',
    'eff.pois': 'Poisons +{0}',
    'eff.res': 'Resurrections +{0}',
    'eff.ink': 'Anti inkub rnk. {0}',
    'eff.suk': 'Anti sukkub rnk. {0}',
    'eff.wit': 'Anti witch rnk. {0}',
    'eff.ham': 'Anti golem hammers rnk. {0}',
    'eff.enf': 'Extra shadow attacks +{0}',
    'eff.gor': 'Gorgon def rnk. +{0}',
    'manticore': 'MANTICORE',
    'sukkub': 'SUKKUBUS',
    'inkub': 'INKUBUS',
    'rider': 'RIDER',
    'nun': 'NUN',
    'fire': 'FIRE ELEM.',
    'gorgon': 'GORGON',
    'lightshadow': 'LIGHT SHADOW',
    'martyr': 'MARTYR',
    'dryad': 'DRYAD',
    'abyssoul': 'ABYSS SOUL',
    'faceless': 'FACELESS',
    'babayaga': 'BABA YAGA',
    'manticore.gn': 'MANTICORE',
    'sukkub.gn': 'SUKKUBUS',
    'inkub.gn': 'INKUBUS',
    'rider.gn': 'RIDER',
    'nun.gn': 'NUN',
    'fire.gn': 'FIRE ELEM.',
    'gorgon.gn': 'GORGON',
    'lightshadow.gn': 'LIGHT SHADOW',
    'martyr.gn': 'MARTYR',
    'dryad.gn': 'DRYAD',
    'abyssoul.gn': 'ABYSS SOUL',
    'faceless.gn': 'FACELESS',
    'babayaga.gn': 'BABA YAGA',
    'need.ns' : 'Need a Nuk-Serra',
    'need.ns.count': 'Need {count} Nuk-Serra',
    'token': 'TOKEN',
    'ark': 'THE ARK',
    'tokens': 'TOKENS',
    'ur.tokens': 'Your tokens:',
    'ark.hlp.1': 'The Ark: Premium Heroes',
    'ark.hlp.2': 'All The Ark Heroes have 12 forces',
    'ark.hlp.3': 'Every Ark Hero can be exchanged back to tokens (for the same ammount)',
    'ark.hlp.4': 'The more',
    'ark.d.1.1': 'Welcome to the Ark!',
    'ark.d.1.2': 'The Ark Heroes are premium heroes for exceptionally wealthy players.',
    'ark.d.1.3': 'Only buy them if the amounts offered are not too high for you and the purchase will not create financial difficulties.',
    'ark.d.1.4': 'Remember, it is just a game!',
    'ark.d.3': 'Any Ark Hero can be exchanged back for the same amount of tokens the hero was purchased.',
    'ark.d.4.1': 'The more heroes - the higher limit.',
    'ark.d.4.2': 'Limits for improving rank and forces can be increased by purchasing more Ark Heroes.',
    'ark.d.4.3': 'For example, Balthazar is a hero with all forces of max rank 15.',
    'ark.d.4.4': 'However, with another Ark hero, Balthazar rank and all his forces can be upgraded to 16.',
    'ya.promo.1': 'You have successfully activated the promo code and now receive a special bonus: 100 emeralds!',
    'ya.promo.2': 'You have successfully activated the promo code and now receive a special bonus: experience +100% for 8 hours!',
    'ya.promo.3': 'You receive a special bonus: Silver set! Super bonuses for 1 hour! Experience +100%, Health per level +100%, Regeneration speed X10!',
    'r.10': 'RANK 10',
    'elit': 'ELITE',
    'mon': 'MONASTERY',
    'mon.cc': 'Mystery place',
    'no.tok': 'Not enough tokens!',
    'need.ark' : 'Need +1 Ark hero',
    'do.exch': 'Exchange!'
}
