import {Tr} from "@/i18n/i18n";
import {SkillType} from "@/store/place/types";


export class StringUtils {

    public static convertSkillTypeToString(skillType: SkillType) {
        switch (skillType) {
            case SkillType.ATTACK:
                return Tr('ataka').toUpperCase();
            case SkillType.DEFENCE:
                return Tr('zaschita').toUpperCase();
            case SkillType.HEALTH:
                return Tr('zdorove').toUpperCase();
        }
    }

    // мне это еще пригодится
    public static declensionSkillName(skillType: SkillType) {
        switch (skillType) {
            case SkillType.ATTACK:
                return Tr('ataku');
            case SkillType.DEFENCE:
                return Tr('zaschitu');
            case SkillType.HEALTH:
                return Tr('zdorove');
        }
    }

    public static formatString(str: string, ...args: any[]) {
        for (let i = 0; i < args.length; i++) {
            str = str.replace('{' + i + '}', args[i]+"");
        }
        return str;
    }

    public static capitalizeFirstLetter(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

}
